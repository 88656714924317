import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Space,
  Switch,
  Typography,
} from 'antd';
import { History, Location } from 'history';
import { withRouter } from 'react-router-dom';

import { ApiError, handleError } from '../../../../api/base';
import FiltersService from '../../../../api/filters';
import {
  CategoryModel,
  initialNewCategoryModel,
} from '../../../../models/category';
import { ProductStatusEnum } from '../../../../models/product-library';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  createCategory,
  deleteCategory,
  updateCategory,
} from '../../../../store/features/category/categorySlice';
import { propsAreEqual } from '../../../../util';
import FormWrapper from '../../../elements/FormWrapper';
import MultiLevelSelect from '../../../elements/MultiLevelSelect';
import { SelectDataItem } from '../../../elements/MultiLevelSelect/types';
import { RoutePath } from '../../../views/AppRoot/types';
import './CategoryForm.less';
import { CategoryFormLocationState } from './types';

interface CategoryFormProps {
  history: History;
  location: Location<CategoryFormLocationState>;
}

export interface CategoryProps {
  name: string;
  status: string;
  parent_id?: string | boolean;
}

const CategoryForm: FC<CategoryFormProps> = (props) => {
  const { history, location } = props;
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const { deletingCategory, savingCategory } = useAppSelector(
    (state) => state.category
  );
  const [categoryFilters, setCategoryFilters] = useState<SelectDataItem[]>([]);
  const [fetchingFilters, setFetchingFilters] = useState<boolean>(false);
  const [category, setCategory] = useState<string>();
  const categoryLocationData = location.state?.data;

  const isNewCategory = useMemo(
    () => !categoryLocationData,
    [categoryLocationData]
  );
  const fields = useMemo(
    () =>
      Object.keys(
        isNewCategory ? initialNewCategoryModel : categoryLocationData
      ),
    [isNewCategory, categoryLocationData]
  );

  const onError = useCallback((err: ApiError) => {
    handleError(err);
  }, []);

  useEffect(() => {
    if (!categoryLocationData) return;

    const { parent_id } = categoryLocationData;
    setCategory(parent_id);
  }, [categoryLocationData]);

  useEffect(() => {
    (async () => {
      setFetchingFilters(true);
      try {
        const categoryFiltersResp = await FiltersService().getFilters();
        setCategoryFilters(categoryFiltersResp.data.categories || []);
        setFetchingFilters(false);
      } catch (error) {
        console.log('error', error);
      }
    })();
  }, []);

  const saveCategory = useCallback(
    async (categoryValue: CategoryModel) => {
      const { name, statusCheckValue } = categoryValue;

      let status: string;
      let newCategory: CategoryProps = { name: '', status: '', parent_id: '' };

      if (statusCheckValue) {
        status = ProductStatusEnum.approved;
      } else {
        status = ProductStatusEnum.needs_approval;
      }

      newCategory = {
        name,
        status,
      };

      if (category && category !== categoryLocationData.parent_id) {
        newCategory = { ...newCategory, parent_id: category ?? false };
      }

      const res: any = isNewCategory
        ? await dispatch(
            createCategory({
              category: newCategory,
            })
          )
        : await dispatch(
            updateCategory({
              categoryId: categoryLocationData.uuid,
              category: newCategory,
            })
          );

      if (res.error) {
        onError({ error: res.payload.error });
      } else {
        message.success('Category saved.');
        history.goBack();
      }
    },
    [
      category,
      dispatch,
      history,
      isNewCategory,
      categoryLocationData?.parent_id,
      categoryLocationData?.uuid,
      onError,
    ]
  );

  const onSubmit = useCallback(
    (values: CategoryModel) => {
      form.validateFields(fields).then(() => saveCategory(values));
    },
    [fields, form, saveCategory]
  );

  const getInitialValues = () => {
    if (isNewCategory) {
      return { ...initialNewCategoryModel };
    }

    const { status } = categoryLocationData;
    return {
      ...categoryLocationData,
      statusCheckValue: status === ProductStatusEnum.approved,
    };
  };

  const handleCategorySelect = useCallback(
    (category: SelectDataItem | 'All' = 'All') => {
      if (category !== 'All') {
        setCategory(category.key);
      }
    },
    []
  );

  const handleDeleteCategory = useCallback(async () => {
    message.loading(
      {
        content: 'Deleting category...',
        key: 'deleting-category-message',
      },
      0
    );

    const res: any = await dispatch(deleteCategory(categoryLocationData.uuid));
    if (res.error) {
      onError({ error: res.payload.error });
    } else {
      message.destroy('deleting-category-message');
      message.success(`Category '${categoryLocationData.name}' deleted.`);
      history.goBack();
    }
  }, [
    dispatch,
    categoryLocationData?.uuid,
    onError,
    categoryLocationData?.name,
    history,
  ]);

  const handleConfirmDeleteCategory = useCallback(
    (e: any) => {
      e.preventDefault();
      Modal.confirm({
        title: `Are you sure you want to delete the category "${categoryLocationData.name}"?`,
        icon: <ExclamationCircleOutlined />,
        content: `You will no longer be able to add products to the "${categoryLocationData.name}" category after it has been deleted.`,
        okType: 'danger',
        okText: 'Delete',
        onOk: handleDeleteCategory,
      });
    },
    [categoryLocationData?.name, handleDeleteCategory]
  );

  const selected: string | undefined = useMemo(() => {
    if (!categoryLocationData) return undefined;

    const { parent_id } = categoryLocationData;
    return parent_id;
  }, [categoryLocationData]);

  return (
    <FormWrapper
      title={`${isNewCategory ? 'Create new' : 'Edit'} category`}
      onClose={() => history.push(RoutePath.MetaData)}
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={getInitialValues()}
        onFinish={onSubmit}
      >
        <Form.Item
          label="Category Name"
          name="name"
          rules={[{ required: true, message: 'Category name is required.' }]}
        >
          <Input placeholder="Category name" />
        </Form.Item>

        <Form.Item required={false}>
          <div>
            <Form.Item>
              <MultiLevelSelect
                data={categoryFilters}
                loading={fetchingFilters}
                onChange={handleCategorySelect}
                selected={selected}
                buttonMaxWidth="100%"
              />
            </Form.Item>
          </div>
        </Form.Item>

        <Form.Item label="Status">
          <div className="category-form-status">
            <Typography>Librarian Approved</Typography>
            <Form.Item name="statusCheckValue" valuePropName="checked">
              <Switch />
            </Form.Item>
          </div>
        </Form.Item>
        {!isNewCategory ? (
          <Form.Item label="Details">
            <div className="category-form-details">
              <Typography>Number of models in library</Typography>
              <Typography>{categoryLocationData.in_library}</Typography>
            </div>
            <div className="category-form-details">
              <Typography>
                Number of models in processing / master librarian list
              </Typography>
              <Typography>{categoryLocationData.in_processing}</Typography>
            </div>
            <Button
              className="link-button"
              type="link"
              onClick={() => {
                history.push({
                  state: { category: categoryLocationData },
                  pathname: RoutePath.ProductLibrary,
                });
              }}
            >
              View in Library
            </Button>
          </Form.Item>
        ) : null}
        <Form.Item>
          {isNewCategory ? (
            <Space style={{ float: 'right' }}>
              <Button onClick={() => history.goBack()}>Cancel</Button>
              <Button
                loading={savingCategory}
                data-cy="category-form-submit-btn"
                htmlType="submit"
                type="primary"
              >
                Add
              </Button>
            </Space>
          ) : (
            <Space style={{ float: 'right' }}>
              {!categoryLocationData.in_use ? (
                <Button
                  loading={deletingCategory}
                  danger
                  type="primary"
                  onClick={handleConfirmDeleteCategory}
                >
                  Delete
                </Button>
              ) : null}
              <Button loading={savingCategory} type="primary" htmlType="submit">
                Save
              </Button>
            </Space>
          )}
        </Form.Item>
      </Form>
    </FormWrapper>
  );
};

export default withRouter<any, any>(memo(CategoryForm, propsAreEqual));
