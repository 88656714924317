import { LeftOutlined } from '@ant-design/icons';
import { Button, Menu, Space } from 'antd';
import React from 'react';
import './DropdownMenu.less';
import { SelectDataItem } from './types';

interface DropdownMenuProps {
  parentItem: SelectDataItem | undefined;
  currentMenuItems: SelectDataItem[];
  handleMenuClick: (key: string) => void;
  handleViewAllBtnClick: () => void;
  handleBackBtnClick: () => void;
  getParent: (itemKey: string) => SelectDataItem | undefined;
}

export const DropdownMenu: React.FC<DropdownMenuProps> = ({
  parentItem,
  currentMenuItems,
  handleMenuClick,
  handleViewAllBtnClick,
  handleBackBtnClick,
  getParent,
}) => {
  return (
    <Menu
      onClick={({ key }) => handleMenuClick(key)}
      className="multi-level-select-dropdown-menu"
    >
      {parentItem && (
        <Menu.Item key="back" onClick={handleBackBtnClick}>
          <div className="multi-level-select-dropdown-back-button">
            <LeftOutlined />
            {parentItem.parent_id === 0
              ? 'All'
              : getParent(parentItem.parent_id as string)?.name}
          </div>
        </Menu.Item>
      )}
      <Menu.Item key="all-btn" disabled>
        <div className="multi-level-select-dropdown-select-all-container">
          <span style={{ fontWeight: 'bold' }}>
            {!parentItem ? 'All' : parentItem.name}
          </span>
          <Button
            type="primary"
            className="multi-level-select-dropdown-select-all-button"
            onClick={(e) => {
              e.stopPropagation();
              handleViewAllBtnClick();
            }}
          >
            View All
          </Button>
        </div>
      </Menu.Item>
      {currentMenuItems.map((item) => (
        <Menu.Item key={item.key}>
          <Space align="center" size="middle">
            <span style={{ marginLeft: parentItem ? '1rem' : 0 }}>
              {item.name}
            </span>
          </Space>
        </Menu.Item>
      ))}
    </Menu>
  );
};
