import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import axios from 'axios';
import { ApiError, handleError } from '../../../api/base';
import { OvrProjectCellMediaFileDeleteRequest } from '../../../api/media/reqres';
import MediaService from '../../../api/media/service';
import { MediaModel } from '../../../models/media';

export interface MediaState {
  value: MediaModel | null;
  isfetchingcurrentmedia: boolean;
  error: { code: number; message: string } | null;
  signedUrl: string;
  ovrProjectCellSignedUrl: string;
  isGettingSignedUrl: boolean;
  isGettingOvrProjectCellSignedUrl: boolean;
  isMediaServiceUploading: boolean;
  isOvrProjectCellMediaServiceUploading: boolean;
  isDeletingOvrProjectMediaFile: boolean;
  isUploadingToS3: boolean;
  isUploading: boolean;
  uploadingFiles: { [key: string]: boolean };
}

const initialState: MediaState = {
  value: null,
  isfetchingcurrentmedia: false,
  error: null,
  signedUrl: '',
  ovrProjectCellSignedUrl: '',
  isGettingSignedUrl: false,
  isGettingOvrProjectCellSignedUrl: false,
  isMediaServiceUploading: false,
  isOvrProjectCellMediaServiceUploading: false,
  isDeletingOvrProjectMediaFile: false,
  isUploadingToS3: false,
  isUploading: false,
  uploadingFiles: {},
};

export interface UploadedMedia {
  objectKey: string;
  name: string;
  size: number;
  uuid: string;
  media_id: string;
  media_version_id: string;
  obj?: {
    status: 'new' | 'active' | 'completed';
  };
}

export const fetchCurrentMedia = createAsyncThunk(
  'media/fetchMedia',
  async (productId: string, { rejectWithValue }) => {
    try {
      const resp = await MediaService().getCurrentMedia(productId);
      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSignedUrl = createAsyncThunk(
  'media/getSignedUrl',
  async ({ fileName, cellId }: { fileName: string; cellId: string }) => {
    const resp = await MediaService().getSignedUploadUrl(fileName, cellId);
    return resp.data;
  }
);

export const uploadImageFileToS3Thunk = createAsyncThunk(
  'media/uploadImageFileToS3',
  async (
    { signedUrl, file }: { signedUrl: string; file: any },
    { rejectWithValue }
  ) => {
    try {
      await axios.put(signedUrl, file, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      });
    } catch (error) {
      return rejectWithValue(`S3 upload error for file: ${file?.name}`);
    }
  }
);

export const mediaServiceUploadNotification = createAsyncThunk(
  'media/mediaServiceUploadNotification',
  async (uploadedMedia: UploadedMedia) => {
    const resp = await MediaService().mediaUploaded(uploadedMedia);
    return resp.data;
  }
);

export const getOvrProjectCellSignedUrl = createAsyncThunk(
  'media/getOvrProjectCellSignedUrl',
  async ({
    fileName,
    projectCellId,
  }: {
    fileName: string;
    projectCellId: string;
  }) => {
    const resp = await MediaService().getOvrProjectCellSignedUploadUrl(
      fileName,
      projectCellId
    );
    return resp.data;
  }
);

export const deleteOvrProjectCellMediaFile = createAsyncThunk(
  'media/deleteOvrProjectCellMediaFile',
  async ({
    ovrProjectCellId,
    mediaVersionId,
    mediaId,
  }: OvrProjectCellMediaFileDeleteRequest) => {
    await MediaService().deleteOvrProjectCellMediaFile({
      ovrProjectCellId,
      mediaVersionId,
      mediaId,
    });
  }
);

export const ovrProjectCellMediaServiceUploadNotification = createAsyncThunk(
  'media/ovrProjectCellMediaServiceUploadNotification',
  async (uploadedMedia: UploadedMedia) => {
    const resp = await MediaService().ovrProjectCellMediaUploaded(
      uploadedMedia
    );
    return resp.data;
  }
);

export const cancelAllUploads = createAsyncThunk(
  'media/cancelAllUploads',
  async (_, { dispatch }) => {
    const cancelTokenSource = (window as any).cancelTokenSource;
    if (cancelTokenSource) {
      cancelTokenSource.cancel('Operation canceled by the user.');
      (window as any).cancelTokenSource = undefined;
    }

    dispatch(mediaSlice.actions.resetUploadingFiles());
  }
);

export const mediaSlice = createSlice({
  name: 'media',
  initialState,
  reducers: {
    setIsUploading: (state, action: PayloadAction<boolean>) => {
      state.isUploading = action.payload;
    },
    setFileUploading: (
      state,
      action: PayloadAction<{ fileId: string; isUploading: boolean }>
    ) => {
      state.uploadingFiles[action.payload.fileId] = action.payload.isUploading;
    },
    resetUploadingFiles: (state) => {
      state.uploadingFiles = {};
      state.isUploading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteOvrProjectCellMediaFile.pending, (state) => {
        state.isDeletingOvrProjectMediaFile = true;
      })
      .addCase(deleteOvrProjectCellMediaFile.fulfilled, (state) => {
        state.isDeletingOvrProjectMediaFile = false;
      })
      .addCase(deleteOvrProjectCellMediaFile.rejected, (state, action) => {
        state.isDeletingOvrProjectMediaFile = false;
        if (action.payload) {
          state.error = (action.payload as ApiError).error;
          handleError(action.payload as ApiError);
        } else {
          state.error = {
            code: (action.error.code as unknown as number) || 500,
            message:
              action.error.message ||
              'Oops! Something went wrong. Please refresh and try again.',
          };
          handleError({ error: state.error });
        }
      })
      .addCase(fetchCurrentMedia.pending, (state) => {
        state.isfetchingcurrentmedia = true;
      })
      .addCase(fetchCurrentMedia.fulfilled, (state, action) => {
        state.value = action.payload;
        state.isfetchingcurrentmedia = false;
      })
      .addCase(fetchCurrentMedia.rejected, (state, action) => {
        state.isfetchingcurrentmedia = false;
        if (action.payload) {
          state.error = (action.payload as ApiError).error;
          handleError(action.payload as ApiError);
        } else {
          state.error = {
            code: (action.error.code as unknown as number) || 500,
            message:
              action.error.message ||
              'Oops! Something went wrong. Please refresh and try again.',
          };
          handleError({ error: state.error });
        }
      })
      .addCase(getSignedUrl.pending, (state) => {
        state.isGettingSignedUrl = true;
      })
      .addCase(getSignedUrl.fulfilled, (state, action) => {
        state.isGettingSignedUrl = false;
        state.signedUrl = action.payload.signedUrl;
      })
      .addCase(getSignedUrl.rejected, (state, action) => {
        state.isGettingSignedUrl = false;
        if (action.payload) {
          state.error = (action.payload as ApiError).error;
          handleError(action.payload as ApiError);
        } else {
          state.error = {
            code: (action.error.code as unknown as number) || 500,
            message:
              action.error.message ||
              'Oops! Something went wrong. Please refresh and try again.',
          };
          handleError({ error: state.error });
        }
      })
      .addCase(uploadImageFileToS3Thunk.pending, (state) => {
        state.isUploadingToS3 = true;
      })
      .addCase(uploadImageFileToS3Thunk.fulfilled, (state) => {
        state.isUploadingToS3 = false;
      })
      .addCase(uploadImageFileToS3Thunk.rejected, (state, action) => {
        state.isUploadingToS3 = false;
        if (action.payload) {
          state.error = (action.payload as ApiError).error;
          handleError(action.payload as ApiError);
        } else {
          state.error = {
            code: (action.error.code as unknown as number) || 500,
            message:
              action.error.message ||
              'Oops! Something went wrong. Please refresh and try again.',
          };
          handleError({ error: state.error });
        }
      })
      .addCase(getOvrProjectCellSignedUrl.pending, (state) => {
        state.isGettingOvrProjectCellSignedUrl = true;
      })
      .addCase(getOvrProjectCellSignedUrl.fulfilled, (state, action) => {
        state.isGettingOvrProjectCellSignedUrl = false;
        state.ovrProjectCellSignedUrl = action.payload.signedUrl;
      })
      .addCase(getOvrProjectCellSignedUrl.rejected, (state, action) => {
        state.isGettingOvrProjectCellSignedUrl = false;
        if (action.payload) {
          state.error = (action.payload as ApiError).error;
          handleError(action.payload as ApiError);
        } else {
          state.error = {
            code: (action.error.code as unknown as number) || 500,
            message:
              action.error.message ||
              'Oops! Something went wrong. Please refresh and try again.',
          };
          handleError({ error: state.error });
        }
      })
      .addCase(mediaServiceUploadNotification.pending, (state) => {
        state.isMediaServiceUploading = true;
      })
      .addCase(mediaServiceUploadNotification.fulfilled, (state) => {
        state.isMediaServiceUploading = false;
      })
      .addCase(mediaServiceUploadNotification.rejected, (state, action) => {
        state.isMediaServiceUploading = false;
        if (action.payload) {
          state.error = (action.payload as ApiError).error;
          handleError(action.payload as ApiError);
        } else {
          state.error = {
            code: (action.error.code as unknown as number) || 500,
            message:
              action.error.message ||
              'Oops! Something went wrong. Please refresh and try again.',
          };
          handleError({ error: state.error });
        }
      })
      .addCase(
        ovrProjectCellMediaServiceUploadNotification.pending,
        (state) => {
          state.isOvrProjectCellMediaServiceUploading = true;
        }
      )
      .addCase(
        ovrProjectCellMediaServiceUploadNotification.fulfilled,
        (state) => {
          state.isOvrProjectCellMediaServiceUploading = false;
        }
      )
      .addCase(
        ovrProjectCellMediaServiceUploadNotification.rejected,
        (state, action) => {
          state.isOvrProjectCellMediaServiceUploading = false;
          if (action.payload) {
            state.error = (action.payload as ApiError).error;
            handleError(action.payload as ApiError);
          } else {
            state.error = {
              code: (action.error.code as unknown as number) || 500,
              message:
                action.error.message ||
                'Oops! Something went wrong. Please refresh and try again.',
            };
            handleError({ error: state.error });
          }
        }
      )
      .addCase(cancelAllUploads.fulfilled, (state) => {
        state.uploadingFiles = {};
        state.isUploading = false;
      });
  },
});

export const { setIsUploading, setFileUploading } = mediaSlice.actions;

export default mediaSlice.reducer;

export const createCancelTokenSource = (): any => {
  const source = axios.CancelToken.source();
  (window as any).cancelTokenSource = source;
  return source;
};
