import { Button, Space, Table, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import useDebounce from '../../../../../hooks/useDebounce';
import { SizeUnitModel } from '../../../../../models/size-unit';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import {
  paginateSizeUnits,
  searchSizeUnitsByName,
} from '../../../../../store/features/metaData/metaDataSlice';
import {
  formatDate,
  getMePermissions,
  UserPermissions,
} from '../../../../../util';
import { DrawerHashRoute } from '../../../../containers/Drawers/types';
import NoResultsFound from '../../../../elements/NoResultsFound';

import { ProductStatusEnum } from '../../../../../models/product-library';
import SearchBar from '../../../../elements/SearchBar/SearchBar';
import { RoutePath } from '../../../AppRoot/types';

const { Text } = Typography;

const SizeUnitTab = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const debouncedSearchQuery = useDebounce<string>(searchQuery, 500);

  const {
    allSizeUnits,
    fetchingSizeUnits,
    searchingSizeUnits,
    count,
    current,
  } = useAppSelector((state) => state.metaData);

  const currentUserPermissions = useMemo(
    (): string[] => getMePermissions(),
    []
  );

  const hasNoSearchResultsFound = useMemo(
    () => !fetchingSizeUnits && !!searchQuery && allSizeUnits.length === 0,
    [fetchingSizeUnits, searchQuery, allSizeUnits.length]
  );

  const hasSizeUnits = !(!fetchingSizeUnits && allSizeUnits.length === 0);

  const columns: ColumnsType<SizeUnitModel> = useMemo(
    () => getColumns(currentUserPermissions, history),
    [currentUserPermissions, history]
  );

  useEffect(() => {
    dispatch(
      searchSizeUnitsByName({
        query: debouncedSearchQuery,
      })
    );
  }, [debouncedSearchQuery, dispatch]);

  const updateSearchQuery = useCallback((query: string) => {
    setSearchQuery(query);
  }, []);

  const paginate = useCallback(
    (page: number, pageSize: number) => {
      dispatch(
        paginateSizeUnits({
          page,
          pageSize,
          queryStr: debouncedSearchQuery,
        })
      );
    },
    [debouncedSearchQuery, dispatch]
  );

  const paginationSetting = {
    hideOnSinglePage: false,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    onChange: paginate,
    onShowSizeChange: paginate,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    style: {
      marginTop: '0px',
      padding: '2rem 1rem',
      background: 'white',
      borderRadius: '0 0 4px 4px',
    },
    total: count,
    current,
  };

  return (
    <div>
      <SearchBar
        searchQuery={searchQuery}
        updateSearchQuery={updateSearchQuery}
        isSearching={searchingSizeUnits}
        canCreate={currentUserPermissions.includes(
          UserPermissions.CustomFieldsCreate
        )}
        createLink={DrawerHashRoute.SizeUnitForm}
        createButtonText="Add New Size Unit"
        placeholder="Search Size Units"
      />
      {hasSizeUnits && hasNoSearchResultsFound ? (
        <NoResultsFound
          searchQuery={searchQuery}
          onClear={() => updateSearchQuery('')}
        />
      ) : (
        <Table
          columns={columns}
          dataSource={allSizeUnits}
          sortDirections={['ascend', 'descend', 'ascend']}
          pagination={paginationSetting}
          loading={searchingSizeUnits}
        />
      )}
    </div>
  );
};

const getColumns = (
  currentUserPermissions: string[],
  history: any
): ColumnsType<SizeUnitModel> => [
  {
    title: 'Size Unit of Measurements',
    dataIndex: 'name',
    width: '35%',
    sorter: (a: SizeUnitModel, b: SizeUnitModel) =>
      a.name.localeCompare(b.name),
  },
  {
    title: '# in Processing',
    dataIndex: 'in_processing',
    defaultSortOrder: 'descend',
    sorter: (a: SizeUnitModel, b: SizeUnitModel) =>
      (a.in_processing || 0) - (b.in_processing || 0),
  },
  {
    title: '# in Library',
    dataIndex: 'in_library',
    defaultSortOrder: 'descend',
    sorter: (a: SizeUnitModel, b: SizeUnitModel) =>
      (a.in_library || 0) - (b.in_library || 0),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    defaultSortOrder: 'descend',
    sorter: (a: SizeUnitModel, b: SizeUnitModel) =>
      a.status && b.status ? (a.status > b.status ? 1 : -1) : 0,
    render: (status: ProductStatusEnum) => (
      <Text
        type={
          status === ProductStatusEnum.needs_approval ? 'danger' : undefined
        }
      >
        {status === ProductStatusEnum.approved ? status : 'Needs Approval'}
      </Text>
    ),
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    defaultSortOrder: 'descend',
    sorter: (a: SizeUnitModel, b: SizeUnitModel) =>
      new Date(a.created_at ?? 0).getTime() -
      new Date(b.created_at ?? 0).getTime(),
    render: (createdAt: string) => formatDate(createdAt),
  },
  {
    title: 'Actions',
    width: 100,
    render: (sizeUnit: SizeUnitModel) => (
      <Space direction="horizontal" size="middle">
        {currentUserPermissions.includes(UserPermissions.MetaDataEdit) && (
          <Button
            type="link"
            className="link-button"
            onClick={() =>
              history.push({
                hash: DrawerHashRoute.SizeUnitForm,
                state: { data: sizeUnit },
              })
            }
          >
            Edit
          </Button>
        )}
        <Button
          className="link-button"
          type="link"
          onClick={() =>
            history.push({
              state: { sizeUnit },
              pathname: RoutePath.ProductLibrary,
            })
          }
        >
          View in Library
        </Button>
      </Space>
    ),
  },
];

export default SizeUnitTab;
