import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiError, handleError } from '../../../api/base';
import ProductStatusService from '../../../api/product-status';

export interface StatusState {
  statusData: any;
  loading: boolean;
  error: {
    code: number | null;
    message: string | null;
  };
}

const initialState: StatusState = {
  statusData: null,
  loading: false,
  error: { code: null, message: null },
};

export const fetchStatusData = createAsyncThunk(
  'status/fetchStatusData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await ProductStatusService().listProductStatus();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const statusSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStatusData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchStatusData.fulfilled, (state, action) => {
        state.loading = false;
        state.statusData = action.payload;
      })
      .addCase(fetchStatusData.rejected, (state, action) => {
        state.loading = false;
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
      });
  },
});

export default statusSlice.reducer;
