import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Dropdown, Spin } from 'antd';
import React, { memo, useState } from 'react';
import { useMultiLevelSelect } from '../../../hooks/userMutiLevelSelect';
import { propsAreEqual } from '../../../util';
import { DropdownMenu } from './DropdownMenu';
import './MultiLevelSelect.less';
import { MultiLevelSelectProps } from './types';

const antSpinIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;

const MultiLevelSelect: React.FC<MultiLevelSelectProps> = ({
  selected,
  data,
  loading,
  onChange,
  buttonMaxWidth,
}) => {
  const {
    selectedItem,
    currentMenuItems,
    parentItem,
    handleMenuClick,
    handleViewAllBtnClick,
    handleBackBtnClick,
    getParent,
  } = useMultiLevelSelect(data, selected, onChange);

  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const handleVisibleChange = (visible: boolean) => {
    setIsMenuVisible(visible);
  };

  return (
    <Dropdown
      className="multi-level-select"
      overlay={
        <DropdownMenu
          parentItem={parentItem}
          currentMenuItems={currentMenuItems}
          handleMenuClick={handleMenuClick}
          handleViewAllBtnClick={handleViewAllBtnClick}
          handleBackBtnClick={handleBackBtnClick}
          getParent={getParent}
        />
      }
      visible={isMenuVisible}
      onVisibleChange={handleVisibleChange}
      trigger={['click']}
      placement="bottomLeft"
    >
      <Button
        className="multi-level-select__button"
        onClick={() => setIsMenuVisible(!isMenuVisible)}
        style={{ maxWidth: buttonMaxWidth }}
      >
        <span className="multi-level-select__button-content">
          {loading ? (
            <>
              <Spin indicator={antSpinIcon} />
              <span style={{ marginLeft: '10px' }}>Loading categories...</span>
            </>
          ) : (
            'Categories'
          )}
          {selectedItem && (
            <>
              {' - '}
              <span className="multi-level-select__selected-item">
                {typeof selectedItem === 'string'
                  ? selectedItem
                  : selectedItem.name}
              </span>
            </>
          )}
        </span>
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default memo(MultiLevelSelect, propsAreEqual);
