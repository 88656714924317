import { useCallback, useEffect, useMemo, useState } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Button, Input, Space, Spin, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Link, useHistory } from 'react-router-dom';

import useDebounce from '../../../hooks/useDebounce';
import { ClientModel, LicenseModel } from '../../../models/client';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  paginateClients,
  searchClientsByName,
} from '../../../store/features/clients/clientsSlice';
import { getMePermissions, UserPermissions } from '../../../util';
import { DrawerHashRoute } from '../../containers/Drawers/types';
import NoResultsFound from '../../elements/NoResultsFound';
import ViewWrapper from '../../elements/ViewWrapper';
import './ClientsView.less';

const antSpinIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;

const { Search } = Input;

export interface ClientModelWithExtensions extends ClientModel {
  markets: string;
  client_type: string;
}

const ClientsView = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const debouncedSearchQuery = useDebounce<string>(searchQuery, 500);

  const { clients, fetchingClients, searchingClients, pagination, error } =
    useAppSelector((state) => state.clients);

  const loading = fetchingClients || searchingClients;

  const hasNoSearchResultsFound = useMemo(
    () => !loading && !!searchQuery && clients.length === 0,
    [clients.length, loading, searchQuery]
  );

  const hasClients = loading || (clients && clients.length > 0);

  const currentUserPermissions = useMemo(
    (): string[] => getMePermissions(),
    []
  );

  const getLicenseStatus = (licenses: LicenseModel[] | undefined): string => {
    if (!licenses || licenses.length === 0) return 'No License';
    const activeLicense = licenses.find((license) => license.is_active);
    if (activeLicense) return 'Active';
    return 'Inactive';
  };

  const getLicenseStatusColor = (status: string) => {
    switch (status) {
      case 'Active':
        return 'green';
      case 'Inactive':
        return 'orange';
      default:
        return 'red';
    }
  };

  const getLicenseType = (licenses: LicenseModel[] | undefined): string => {
    if (!licenses || licenses.length === 0) return 'No License';
    const types = [...new Set(licenses.map((license) => license.type))];
    return types.join(', ');
  };

  const columns: ColumnsType<ClientModel> = [
    {
      title: 'Name',
      width: '35%',
      sorter: (a, b) => ((a.name || '') > (b.name || '') ? 1 : -1),
      render: (client: ClientModelWithExtensions) => (
        <Link to={`/clients/${client?.uuid}`}>
          <Button type="link" className="link-button">
            {client?.name || 'N/A'}
          </Button>
        </Link>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'license',
      width: '15%',
      render: (licenses: LicenseModel[] | undefined) =>
        getLicenseType(licenses),
      sorter: (a, b) => {
        const typeA = getLicenseType(a.license);
        const typeB = getLicenseType(b.license);
        return typeA.localeCompare(typeB);
      },
    },
    {
      title: 'License Status',
      dataIndex: 'license',
      width: '15%',
      render: (licenses: LicenseModel[] | undefined) => {
        const status = getLicenseStatus(licenses);
        return <Tag color={getLicenseStatusColor(status)}>{status}</Tag>;
      },
      sorter: (a, b) => {
        const statusA = getLicenseStatus(a.license);
        const statusB = getLicenseStatus(b.license);
        return statusA.localeCompare(statusB);
      },
    },
    {
      title: 'License Expiry',
      dataIndex: 'license',
      width: '15%',
      render: (licenses: LicenseModel[] | undefined) => {
        if (!licenses || licenses.length === 0) return 'N/A';
        const activeLicense = licenses.find((license) => license.is_active);
        if (activeLicense) {
          return new Date(activeLicense.end_date).toLocaleDateString();
        }
        return 'Expired';
      },
    },
    {
      title: 'Action',
      width: 100,
      render: (client: ClientModelWithExtensions) => {
        return (
          <Space direction="horizontal" size="middle">
            {currentUserPermissions.includes(UserPermissions.ClientsEdit) ? (
              <Button
                type="link"
                className="link-button"
                onClick={() =>
                  history.push({
                    hash: DrawerHashRoute.ClientForm,
                    state: { client },
                  })
                }
              >
                Edit
              </Button>
            ) : null}
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    if (clients.length === 0 && !loading) {
      dispatch(searchClientsByName(''));
    }
  }, [clients.length, dispatch, loading]);

  useEffect(() => {
    dispatch(searchClientsByName(debouncedSearchQuery));
  }, [debouncedSearchQuery, dispatch]);

  const updateSearchQuery = useCallback((query: string) => {
    setSearchQuery(query);
  }, []);

  const paginate = useCallback(
    (page: number, pageSize: number) =>
      dispatch(
        paginateClients({
          page,
          pageSize,
          queryStr: debouncedSearchQuery,
        })
      ),
    [debouncedSearchQuery, dispatch]
  );

  const paginationSetting = {
    hideOnSinglePage: false,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    onChange: paginate,
    onShowSizeChange: paginate,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    style: {
      marginTop: '0px',
      padding: '2rem 1rem',
      background: 'white',
      borderRadius: '0 0 4px 4px',
    },
    total: pagination?.count || 0,
    current: pagination?.offset
      ? Math.floor(pagination.offset / pagination.limit) + 1
      : 1,
    pageSize: pagination?.limit || 10,
  };

  return (
    <ViewWrapper>
      <div className="clients-view__search-bar-container">
        <div className="clients-view__search-bar">
          <div className="clients-view__search-bar-left">
            <Search
              className="clients-view__search-input"
              allowClear={!searchingClients}
              value={searchQuery}
              onChange={(e) => updateSearchQuery(e.target.value)}
              onSearch={(value) => dispatch(searchClientsByName(value))}
              enterButton
              placeholder="Search clients..."
              suffix={loading && <Spin indicator={antSpinIcon} />}
            />
          </div>
          <div className="clients-view__search-bar-right">
            {currentUserPermissions.includes(UserPermissions.ClientsCreate) ? (
              <Link to={DrawerHashRoute.ClientForm}>
                <Button type="primary">Add New Client</Button>
              </Link>
            ) : null}
          </div>
        </div>
      </div>
      <div className="clients-view__content">
        {error.message && (
          <div className="error-message">Error: {error.message}</div>
        )}
        <div className="clients-view__table-wrapper">
          {hasClients && hasNoSearchResultsFound ? (
            <NoResultsFound
              searchQuery={searchQuery}
              onClear={() => updateSearchQuery('')}
            />
          ) : (
            <Table
              sortDirections={['ascend', 'descend', 'ascend']}
              columns={columns}
              rowKey={(client: ClientModel) => `${client.uuid}-row-key`}
              dataSource={clients}
              pagination={paginationSetting}
              loading={loading}
            />
          )}
        </div>
      </div>
    </ViewWrapper>
  );
};

export default ClientsView;
