export interface FieldModel {
  field_id: number;
  field_key: string;
  value: string | number;
  name: string;
  format?: number;
}

export interface TagModel {
  name: string;
  value: string;
  set: number;
  tagid: number;
}

type StatusType =
  | 'needs_approval'
  | 'approved'
  | 'rejected'
  | 'pending'
  | 'active'
  | 'inactive'
  | 'deleted';

type LicenseType =
  | 'Trial License'
  | 'Annual License'
  | 'Project License'
  | 'No License';

export interface LicenseModel {
  name: string;
  uuid: string;
  client_id: string;
  start_date: string;
  end_date: string;
  type: LicenseType;
  status: string;
  quantity: number;
  quantity_used: number;
  quantity_available: number;
  created_by: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  expired: boolean;
  is_active: boolean;
}

export interface ClientModel {
  active?: ClientActive;
  deleted_at?: string;
  created_at?: string;
  id?: number;
  name: string;
  parent_id?: string;
  short_name?: string;
  license?: LicenseModel[];
  updated_at?: string;
  status: StatusType;
  tags?: TagModel[];
  uuid: string;
  fields?: FieldModel[] | FieldModel;
}

export const initialNewClientModel = {
  name: '',
  fields: {
    default_market: '',
    client_type: '',
    client_notes: '',
  },
};

export interface ListClientModel {
  uuid: string;
  name: string;
}

export enum ClientActive {
  Active = 1,
  Inactive = 0,
}

export const MarketMap = {
  '66': 'United States',
  '67': 'Canada',
  '291': 'All Markets',
  '74': 'All Markets',
};
