import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);

export const formatDate = (
  date: string | number | Date | dayjs.Dayjs | null | undefined,
  format = 'MMMM D, YYYY, h:mm A',
  timezone?: string
) => {
  const utcDate = dayjs.utc(date);
  if (timezone) {
    return utcDate.tz(timezone).format(format);
  }
  return utcDate.format(format);
};
