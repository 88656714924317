import { useCallback, useMemo } from 'react';

import {
  CheckOutlined,
  CloseOutlined,
  CopyOutlined,
  DeleteFilled,
  DownloadOutlined,
  EditFilled,
  ExclamationCircleOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import {
  ConfigProvider,
  Empty,
  Modal,
  Space,
  Switch,
  Table,
  Tag,
  Tooltip,
  Typography,
  message,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { History } from 'history';
import './AutomationQueueFileTable.less';

import { FileModel } from '../../../../../models/media';
import { CellModel } from '../../../../../models/online-virtual-research';
import { OvrFileModel } from '../../../../../models/ovr-file';
import { UserModel } from '../../../../../models/user';
import {
  deleteOvrCell,
  downloadOvrCell,
  paginateOvrCells,
  searchOvrCellsByName,
  showOvrCellInProjects,
} from '../../../../../store/features/cells/cellsSlice';
import {
  deleteOvrFile,
  paginateOvrFiles,
} from '../../../../../store/features/ovrFiles/ovrFilesSlice';
import { useAppDispatch, useAppSelector } from '../../../../../store/index';
import {
  UserPermissions,
  UserRoles,
  formatDate,
  getMePermissions,
  getMeRoles,
} from '../../../../../util';
import { DrawerHashRoute } from '../../../../containers/Drawers/types';

const { Text } = Typography;

interface AutomationQueueFileTableProps {
  history: History;
  tab: string;
  loading: boolean;
}

const customizeRenderEmpty = () => (
  <Empty description="No Data" image={Empty.PRESENTED_IMAGE_SIMPLE} />
);

const AutomationQueueFileTable = ({
  history,
  tab,
  loading,
}: AutomationQueueFileTableProps) => {
  const dispatch = useAppDispatch();
  const {
    // ovrFiles,
    processingOvrFiles,
    failedOvrFiles,
    // totalCount: ovrFilesTotalCount,
    processingOvrFilesTotalCount,
    failedOvrFilesTotalCount,
    ovrFileSearchQuery,
  } = useAppSelector((state) => state.ovrFiles);

  const {
    ovrCells,
    totalCount: ovrCellsTotalCount,
    cellSearchQuery,
  } = useAppSelector((state) => state.cells);

  const currentUserPermissions = useMemo(
    (): string[] => getMePermissions(),
    []
  );

  const currentUserRoles = useMemo(() => {
    return getMeRoles();
  }, []);

  const isClient = useMemo(() => {
    return currentUserRoles.includes(UserRoles.client);
  }, [currentUserRoles]);

  const onSuccess = useCallback((ovrFile: OvrFileModel) => {
    message.destroy('deleting-ovrFile-message');
    message.success(`OVR Project File '${ovrFile?.name}' deleted.`);
  }, []);

  const handleSwitchToggle = useCallback(
    async (cell: CellModel, switchValue: boolean) => {
      await dispatch(
        showOvrCellInProjects({ cellUUID: cell.uuid!, approved: switchValue })
      );
      if (cellSearchQuery) {
        await dispatch(
          searchOvrCellsByName({
            query: cellSearchQuery,
            options: { status: 'completed' },
          })
        );
      } else {
        await dispatch(
          searchOvrCellsByName({ query: '', options: { status: 'completed' } })
        );
      }
    },
    [cellSearchQuery, dispatch]
  );

  const handleDownloadOVRCell = useCallback(
    async (cell: CellModel) => {
      const cellId = cell.uuid;

      if (!cellId) {
        return;
      }

      const downloadResp = await dispatch(downloadOvrCell(cellId)).unwrap();

      const ovrFile = downloadResp.media_files.find(
        (mediaFile: FileModel) => mediaFile.type === 'ovr'
      );

      if (!ovrFile) {
        message.error('No OVR file to download.');
        return;
      }

      const downloadLinkEl = document.createElement('a');
      downloadLinkEl.href = ovrFile.media_url;
      downloadLinkEl.click();
      downloadLinkEl.remove();
    },

    [dispatch]
  );

  const handleDeleteOVRFile = useCallback(
    async (ovrFile: OvrFileModel) => {
      message.loading(
        {
          content: 'Deleting OVR Project File...',
          key: 'deleting-ovrFile-message',
        },
        0
      );
      await dispatch(deleteOvrFile(ovrFile));
      onSuccess(ovrFile);
    },
    [dispatch, onSuccess]
  );

  const handleDeleteOVRCell = useCallback(
    async (cell: CellModel) => {
      message.loading(
        { content: 'Deleting OVR Cell...', key: 'deleting-ovrcell-message' },
        0
      );
      await dispatch(deleteOvrCell(cell.uuid!));

      message.destroy('deleting-ovrcell-message');
      message.success(`OVR Cell '${cell?.name}' deleted.`);
    },
    [dispatch]
  );

  const handleConfirmDeleteOVRFile = useCallback(
    (e: MouseEvent, ovrFile: OvrFileModel) => {
      e.preventDefault();
      Modal.confirm({
        title: `Delete OVR Project File '${ovrFile?.name}'?`,
        icon: <ExclamationCircleOutlined />,
        content: 'You will not be able to recover this OVR project file.',
        okText: 'Delete',
        onOk: () => handleDeleteOVRFile(ovrFile),
      });
    },
    [handleDeleteOVRFile]
  );

  const handleConfirmDeleteOVRCell = useCallback(
    (e: MouseEvent, cell: CellModel) => {
      e.preventDefault();
      Modal.confirm({
        title: `Delete OVR cell '${cell.name}'?`,
        icon: <ExclamationCircleOutlined />,
        content: 'You will not be able to recover this OVR cell.',
        okText: 'Delete',
        onOk: () => handleDeleteOVRCell(cell),
      });
    },
    [handleDeleteOVRCell]
  );

  const handleOpenLivePreview = (url: string) => {
    window.open(url, '_blank');
  };

  const handleCopyLink = (cell: CellModel) => {
    navigator.clipboard
      .writeText(cell?.cell_preview_url!)
      .then(() => {
        message.success('Copied!');
      })
      .catch((err) => {
        console.error(err);
        message.error('Failed to copy!');
      });
  };

  const columns: ColumnsType<any> = useMemo(() => {
    if (tab === 'Completed') {
      return [
        {
          title: 'Cell Name',
          dataIndex: 'name',
          key: 'name',
          render: (name: string, _) => {
            return <Text style={{ fontWeight: 'bold' }}>{name}</Text>;
          },
        },
        {
          title: 'RS Filename',
          key: 'filename',
          render: (cell: CellModel) => {
            const rsgOrOvrFile = cell?.media_files?.find((file: any) =>
              file?.filename?.match(/\.(rso|ovr)$/i)
            ) as any;

            if (!rsgOrOvrFile) {
              return null;
            }

            return (
              <Text style={{ fontWeight: 'bold' }}>
                {rsgOrOvrFile.filename.replace(/\.(rso|ovr)$/i, '')}
              </Text>
            );
          },
        },
        {
          title: 'Created by',
          dataIndex: 'createdByUser',
          key: 'createdByUser',
          render: (user: UserModel) =>
            user ? (
              <Text>{user?.name}</Text>
            ) : (
              <Text type="secondary" style={{ fontStyle: 'italic' }}>
                No user.
              </Text>
            ),
        },
        {
          title: 'Description',
          dataIndex: 'description',
          key: 'description',
          render: (description: string) =>
            !!description ? (
              <Tooltip title={description} mouseEnterDelay={1}>
                <Text ellipsis style={{ maxWidth: '400px' }}>
                  {description}
                </Text>
              </Tooltip>
            ) : (
              <Text type="secondary" style={{ fontStyle: 'italic' }}>
                No description.
              </Text>
            ),
        },
        {
          title: 'Added',
          dataIndex: 'created_at',
          key: 'created_at',
          render: (date: string) => {
            return <div>{date ? formatDate(date) : null}</div>;
          },
          sorter: (
            a: { created_at: string | number | Date },
            b: { created_at: string | number | Date }
          ) =>
            new Date(a?.created_at).getTime() -
            new Date(b?.created_at)?.getTime(),
        },
        {
          title: 'OVR',
          dataIndex: 'ovr',
          key: 'ovr',
          render: (_: string, record: CellModel) => {
            return (
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                defaultChecked={!!record.approved}
                disabled={false}
                onChange={(switchValue) =>
                  handleSwitchToggle(record, switchValue)
                }
              />
            );
          },
        },
        {
          title: 'Actions',
          key: 'actions',
          render: (cell: CellModel) => {
            const isCellUsedInProject = cell.in_use;

            return (
              <Space direction="horizontal" size="middle">
                <Tooltip title="View">
                  <span
                    onClick={() =>
                      handleOpenLivePreview(cell.cell_preview_url as string)
                    }
                    style={{ cursor: 'pointer' }}
                  >
                    <EyeOutlined className="automation-queue-file-table-action-icon" />
                  </span>
                </Tooltip>

                {currentUserPermissions.includes(
                  UserPermissions.OvrFileManagementDelete
                ) ? (
                  <Tooltip title="Download">
                    <DownloadOutlined
                      onClick={() => handleDownloadOVRCell(cell)}
                      className="automation-queue-file-table-action-icon"
                    />
                  </Tooltip>
                ) : null}

                {currentUserPermissions.includes(
                  UserPermissions.OvrFileManagementEdit
                ) || isClient ? (
                  <Tooltip title="Edit">
                    <EditFilled
                      onClick={() =>
                        history.push({
                          hash: DrawerHashRoute.CustomFileForm,
                          state: { data: cell },
                        })
                      }
                      className="automation-queue-file-table-action-icon"
                    />
                  </Tooltip>
                ) : null}

                {currentUserPermissions.includes(
                  UserPermissions.OvrFileManagementDelete
                ) ? (
                  <Tooltip
                    title={
                      isCellUsedInProject
                        ? "OVR Cell can't be deleted because it's used in an OVR Project"
                        : 'Delete'
                    }
                  >
                    <DeleteFilled
                      disabled={isCellUsedInProject}
                      onClick={(e: any) => {
                        if (isCellUsedInProject) {
                          return;
                        }

                        handleConfirmDeleteOVRCell(e, cell);
                      }}
                      className="automation-queue-file-table-action-icon"
                      style={{
                        opacity: isCellUsedInProject ? 0.5 : 1,
                        cursor: isCellUsedInProject ? 'default' : 'pointer',
                      }}
                    />
                  </Tooltip>
                ) : null}
                <Tooltip title="Copy Link">
                  <span
                    onClick={() => handleCopyLink(cell)}
                    style={{ cursor: 'pointer' }}
                  >
                    <CopyOutlined className="automation-queue-file-table-action-icon" />
                  </span>
                </Tooltip>
              </Space>
            );
          },
        },
      ];
    } else if (tab === 'Processing') {
      return [
        {
          title: 'File Name',
          dataIndex: 'name',
          key: 'name',
          render: (name: string) => {
            return <Text style={{ fontWeight: 'bold' }}>{name}</Text>;
          },
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          render: (ovrFile: OvrFileModel) => {
            if (ovrFile.status === 'locked') {
              return <Text>In progress...</Text>;
            }
            return <Text>New</Text>;
          },
        },
        {
          title: 'Type',
          dataIndex: 'type',
          key: 'type',
          render: (_: any, record: any) => {
            const fileExtension = record?.name?.split('.').pop();
            if (fileExtension === 'rsg') {
              return <Tag color="blue">STANDARD</Tag>;
            } else {
              return <Tag color="gold">CUSTOM</Tag>;
            }
          },
        },
        {
          title: 'Created by',
          dataIndex: 'createdByUser',
          key: 'createdByUser',
          render: (user: UserModel) =>
            user ? (
              <Text>{user?.name}</Text>
            ) : (
              <Text type="secondary" style={{ fontStyle: 'italic' }}>
                No user.
              </Text>
            ),
        },
        {
          title: 'Description',
          dataIndex: 'description',
          key: 'description',
          width: 20,
          render: (description: string) =>
            !!description ? (
              <Tooltip title={description} mouseEnterDelay={1}>
                <Text ellipsis style={{ maxWidth: '100px' }}>
                  {description}
                </Text>
              </Tooltip>
            ) : (
              <Text type="secondary" style={{ fontStyle: 'italic' }}>
                No description.
              </Text>
            ),
        },
        {
          title: 'Added',
          dataIndex: 'created_at',
          key: 'created_at',
          width: 400,
          render: (date: string) => (
            <div
              style={{
                width: '200px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {formatDate(date)}
            </div>
          ),
          sorter: (
            a: { created_at: string | number | Date },
            b: { created_at: string | number | Date }
          ) =>
            new Date(a?.created_at)?.getTime() -
            new Date(b?.created_at)?.getTime(),
        },
        {
          title: 'Actions',
          key: 'actions',
          width: 100, // Set the width to 100px
          render: (ovrFile: OvrFileModel) => {
            return (
              <Space direction="horizontal" size="middle">
                <Tooltip title="Edit">
                  <EditFilled
                    disabled={ovrFile.status === 'locked'}
                    onClick={() =>
                      history.push({
                        hash: DrawerHashRoute.RSGFileForm,
                        state: { data: ovrFile },
                      })
                    }
                    className="automation-queue-file-table-action-icon"
                  />
                </Tooltip>
                <Tooltip title="Delete">
                  <DeleteFilled
                    onClick={(e: any) => handleConfirmDeleteOVRFile(e, ovrFile)}
                    className="automation-queue-file-table-action-icon"
                  />
                </Tooltip>
              </Space>
            );
          },
        },
      ];
    } else if (tab === 'Failed') {
      return [
        {
          title: 'File Name',
          dataIndex: 'name',
          key: 'name',
          render: (name: string) => {
            return <Text style={{ fontWeight: 'bold' }}>{name}</Text>;
          },
          sorter: (a: { name: string }, b: { name: string }) =>
            a.name.localeCompare(b.name),
        },
        {
          title: 'Created by',
          dataIndex: 'createdByUser',
          key: 'createdByUser',
          render: (user: UserModel) =>
            user ? (
              <Text>{user?.name}</Text>
            ) : (
              <Text type="secondary" style={{ fontStyle: 'italic' }}>
                No user.
              </Text>
            ),
        },
        {
          title: 'Created at',
          dataIndex: 'created_at',
          key: 'created_at',
          render: (date: string) => {
            return <div>{date ? formatDate(date) : null}</div>;
          },
          sorter: (
            a: { created_at: string | number | Date },
            b: { created_at: string | number | Date }
          ) =>
            new Date(a?.created_at).getTime() -
            new Date(b?.created_at)?.getTime(),
        },
        {
          title: 'Notes',
          dataIndex: 'notes',
          key: 'notes',
          render: (notes: string) =>
            !!notes ? (
              <Tooltip title={notes} mouseEnterDelay={1}>
                <Text ellipsis style={{ maxWidth: '400px' }}>
                  {notes}
                </Text>
              </Tooltip>
            ) : (
              <Text type="secondary" style={{ fontStyle: 'italic' }}>
                No notes.
              </Text>
            ),
        },
        {
          title: 'Actions',
          key: 'actions',
          render: (file: any) => {
            return (
              <Space direction="horizontal" size="middle">
                {currentUserPermissions.includes(
                  UserPermissions.OvrFileManagementDelete
                ) ? (
                  <Tooltip title={'Delete File'}>
                    <DeleteFilled
                      onClick={() => {
                        handleDeleteOVRFile(file);
                      }}
                      className="automation-queue-file-table-action-icon"
                    />
                  </Tooltip>
                ) : null}
              </Space>
            );
          },
        },
      ];
    }
    return [];
  }, [
    tab,
    handleSwitchToggle,
    currentUserPermissions,
    isClient,
    handleDownloadOVRCell,
    history,
    handleConfirmDeleteOVRCell,
    handleConfirmDeleteOVRFile,
    handleDeleteOVRFile,
  ]);

  let data;
  if (tab === 'Completed') {
    data = ovrCells;
  } else if (tab === 'Processing') {
    data = processingOvrFiles;
  } else if (tab === 'Failed') {
    data = failedOvrFiles;
  }

  const totalCount =
    tab === 'Completed'
      ? ovrCellsTotalCount
      : tab === 'Processing'
      ? processingOvrFilesTotalCount
      : tab === 'Failed'
      ? failedOvrFilesTotalCount
      : 0;

  const paginate = useCallback(
    (page: number, pageSize: number) => {
      if (tab === 'Completed') {
        return dispatch(
          paginateOvrCells({
            page,
            pageSize,
            query: cellSearchQuery,
            options: {
              status: 'completed',
            },
          })
        );
      } else {
        dispatch(
          paginateOvrFiles({
            page,
            pageSize,
            query: ovrFileSearchQuery,
          })
        );
      }
    },
    [cellSearchQuery, dispatch, ovrFileSearchQuery, tab]
  );

  // console.log('ovrCells: ', ovrCells);

  return (
    <ConfigProvider renderEmpty={customizeRenderEmpty}>
      <Table
        loading={loading}
        className="ovr-file-management-files"
        pagination={{
          defaultPageSize: 20,
          total: totalCount,
          onChange: paginate,
        }}
        sortDirections={['ascend', 'descend', 'ascend']}
        columns={columns}
        dataSource={data as OvrFileModel[] | CellModel[]}
        rowKey={(file: any) => `${file.uuid}-row-key`}
      />
    </ConfigProvider>
  );
};

export default AutomationQueueFileTable;
