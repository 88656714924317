import { LoadingOutlined } from '@ant-design/icons';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { Button, Image, Space, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';

import imagePlaceholder from '../../../../../assets/images/product-image-placeholder.png';
import { ListProductLibraryModel } from '../../../../../models/product-library';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import {
  paginateProductLibrary,
  setCurrentPage,
  setPageSize,
} from '../../../../../store/features/productLibrary/productLibrarySlice';
import {
  addProductItemToList,
  removeProductItemFromList,
  searchProductsByName,
} from '../../../../../store/features/productList/productListSlice';
import { formatDate, propsAreEqual } from '../../../../../util';
import ProductsTable from '../../../../containers/Tables/ProductsTable';
import './ProductLibraryItemsList.less';
import ProductLibraryGridList from './components/ProductLibraryGridList';

const { Text } = Typography;
interface ProductLibraryItemsListProps {
  skeletonItemLength?: number;
  productLibrary: ListProductLibraryModel[];
  loading: boolean;
  onLoadMore: () => void;
  hasMore: boolean;
  viewType: ProductLibraryListViewType;
}

export enum ProductLibraryListViewType {
  Grid = 'grid',
  Table = 'table',
}

const ProductLibraryItemsList = ({
  productLibrary,
  loading,
  onLoadMore,
  hasMore,
  viewType,
}: ProductLibraryItemsListProps) => {
  const dispatch = useAppDispatch();
  const { count: productListCount, selectedProducts } = useAppSelector(
    (state) => state.productList
  );
  const productLibraryCount = useAppSelector(
    (state) => state.productLibrary.totalCount
  );
  const currentPage = useAppSelector(
    (state) => state.productLibrary.currentPage
  );
  const pageSize = useAppSelector((state) => state.productLibrary.pageSize);

  const [loadingItems, setLoadingItems] = useState<{ [key: string]: boolean }>(
    {}
  );

  const handleAddProductItemToList = useCallback(
    async (productItem: ListProductLibraryModel) => {
      setLoadingItems((prev) => ({ ...prev, [productItem.uuid]: true }));
      try {
        await dispatch(addProductItemToList(productItem));
        await dispatch(searchProductsByName(''));
        await dispatch(paginateProductLibrary({ page: currentPage, pageSize }));
      } catch (error) {
        console.error('Error adding product item to list', error);
      } finally {
        setLoadingItems((prev) => ({ ...prev, [productItem.uuid]: false }));
      }
    },
    [currentPage, dispatch, pageSize]
  );

  const handleRemoveProductItemFromList = useCallback(
    async (productItem: ListProductLibraryModel) => {
      setLoadingItems((prev) => ({ ...prev, [productItem.uuid]: true }));
      try {
        await dispatch(removeProductItemFromList(productItem.uuid));
        await dispatch(searchProductsByName(''));
        await dispatch(paginateProductLibrary({ page: currentPage, pageSize }));
      } catch (error) {
        console.error('Error removing product item from list', error);
      } finally {
        setLoadingItems((prev) => ({ ...prev, [productItem.uuid]: false }));
      }
    },
    [currentPage, dispatch, pageSize]
  );

  const columns: ColumnsType<ListProductLibraryModel> = useMemo(
    () => [
      {
        title: 'Model',
        dataIndex: 'data',
        key: 'model',
        fixed: 'left',
        width: 100,
        render: (_, productItem) => {
          return (
            <Link
              to={{
                pathname: `/product-library/${productItem.uuid}`,
                state: {
                  productItem,
                },
              }}
            >
              <Image
                style={{
                  width: '100%',
                  maxHeight: '130px',
                  objectFit: 'cover',
                }}
                src={
                  productItem.image_url
                    ? productItem.image_url
                    : imagePlaceholder
                }
                alt={`${productItem.name}`}
                preview={false}
              />
            </Link>
          );
        },
      },
      {
        title: 'UPC',
        dataIndex: 'upc',
        key: 'upc',
        width: 150,
        render: (upc: string) =>
          !!upc ? (
            <Tooltip title={upc} mouseEnterDelay={1}>
              <Text ellipsis style={{ maxWidth: '400px' }}>
                {upc}
              </Text>
            </Tooltip>
          ) : (
            <Text type="secondary" style={{ fontStyle: 'italic' }}>
              N/A
            </Text>
          ),
        defaultSortOrder: 'descend',
        sorter: (a: ListProductLibraryModel, b: ListProductLibraryModel) =>
          Number(`${a?.upc}`?.replace(/\D/g, '')) -
          Number(`${b?.upc}`?.replace(/\D/g, '')),
      },
      {
        title: 'Product Name',
        dataIndex: 'name',
        key: 'name',
        width: 150,
        render: (productName: string) =>
          !!productName ? (
            <Tooltip title={productName} mouseEnterDelay={1}>
              <Text style={{ maxWidth: '150px' }}>{productName}</Text>
            </Tooltip>
          ) : (
            <Text type="secondary" style={{ fontStyle: 'italic' }}>
              No product name.
            </Text>
          ),
        defaultSortOrder: 'descend',
        sorter: (a: ListProductLibraryModel, b: ListProductLibraryModel) =>
          a?.name?.localeCompare(b?.name),
      },
      {
        title: 'Brand',
        dataIndex: 'brand_name',
        key: 'brand',
        width: 100,
        render: (brand: string) =>
          !!brand ? (
            <Tooltip title={brand} mouseEnterDelay={1}>
              <Text style={{ maxWidth: '150px' }}>{brand}</Text>
            </Tooltip>
          ) : (
            <Text type="secondary" style={{ fontStyle: 'italic' }}>
              No brand.
            </Text>
          ),

        defaultSortOrder: 'descend',
        sorter: (a: ListProductLibraryModel, b: ListProductLibraryModel) =>
          a.brand?.name.localeCompare(b.brand?.name),
      },
      {
        title: 'Manufacturer',
        dataIndex: 'manufacturer',
        key: 'manufacturer',
        width: 150,
        render: (manufacturer: string) =>
          !!manufacturer ? (
            <Tooltip title={manufacturer} mouseEnterDelay={1}>
              <Text ellipsis style={{ maxWidth: '400px' }}>
                {manufacturer}
              </Text>
            </Tooltip>
          ) : (
            <Text type="secondary" style={{ fontStyle: 'italic' }}>
              No manufacturer.
            </Text>
          ),
        defaultSortOrder: 'descend',
        sorter: (a: ListProductLibraryModel, b: ListProductLibraryModel) =>
          a.brand?.manufacturer?.name.localeCompare(
            b.brand?.manufacturer?.name
          ),
      },
      {
        title: 'Size',
        dataIndex: 'size',
        key: 'size',
        width: 100,
        render: (size: number) =>
          !!size ? (
            <Tooltip title={size} mouseEnterDelay={1}>
              <Text style={{ maxWidth: '150px' }}>{size}</Text>
            </Tooltip>
          ) : (
            <Text type="secondary" style={{ fontStyle: 'italic' }}>
              No size.
            </Text>
          ),
        defaultSortOrder: 'descend',
        sorter: (a: ListProductLibraryModel, b: ListProductLibraryModel) => {
          const aSize = `${a.size}`?.split(' ')?.[0];
          const bSize = `${b.size}`?.split(' ')?.[0];
          if (aSize && bSize) {
            return Number(aSize) - Number(bSize);
          } else if (aSize) {
            return 1;
          } else if (bSize) {
            return -1;
          } else {
            return 0;
          }
        },
      },
      {
        title: 'Count',
        dataIndex: 'count',
        key: 'count',
        width: 100,
        render: (count: string) =>
          !!count ? (
            <Tooltip title={count} mouseEnterDelay={1}>
              <Text style={{ maxWidth: '150px' }}>{count}</Text>
            </Tooltip>
          ) : (
            <Text type="secondary" style={{ fontStyle: 'italic' }}>
              No count.
            </Text>
          ),
        defaultSortOrder: 'descend',
        sorter: (a: ListProductLibraryModel, b: ListProductLibraryModel) => {
          // For product items that are null/undefined
          const aCount = a.count?.split(' ')?.[0];
          const bCount = b.count?.split(' ')?.[0];
          if (aCount && bCount) {
            return Number(aCount) - Number(bCount);
          } else if (aCount) {
            return 1;
          } else if (bCount) {
            return -1;
          } else {
            return 0;
          }
        },
      },
      {
        title: 'Modeled Date',
        dataIndex: 'created_at',
        key: 'created_at',
        width: 150,
        render: (date: string) => {
          return <div>{date ? formatDate(date) : null}</div>;
        },
        sorter: (a: ListProductLibraryModel, b: ListProductLibraryModel) =>
          new Date(a?.created_at).getTime() - new Date(b?.created_at).getTime(),
      },
      {
        title: 'Actions',
        dataIndex: 'data',
        key: 'actions',
        width: 100,
        render: (_, productItem: ListProductLibraryModel) => {
          const isLoading = loadingItems[productItem.uuid];
          return (
            <Space direction="horizontal" size="small">
              {isLoading ? (
                <LoadingOutlined />
              ) : productItem.in_list === 1 ? (
                <Tooltip title="Remove from Product List">
                  <Button
                    className="link-button"
                    type="link"
                    onClick={() => handleRemoveProductItemFromList(productItem)}
                  >
                    Remove
                  </Button>
                </Tooltip>
              ) : (
                <Tooltip title="Add to Product List">
                  <Button
                    className="link-button"
                    type="link"
                    onClick={() => handleAddProductItemToList(productItem)}
                  >
                    Add
                  </Button>
                </Tooltip>
              )}
            </Space>
          );
        },
      },
    ],
    [handleAddProductItemToList, handleRemoveProductItemFromList, loadingItems]
  );

  const paginate = useCallback(
    (page: number, pageSize: number) => {
      dispatch(setCurrentPage(page));
      dispatch(setPageSize(pageSize));
    },
    [dispatch]
  );

  useEffect(() => {
    if (currentPage !== undefined && pageSize !== undefined) {
      dispatch(paginateProductLibrary({ page: currentPage, pageSize }));
    }
  }, [currentPage, pageSize, dispatch]);

  const paginationSetting = {
    hideOnSinglePage: false,
    showSizeChanger: true,
    defaultPageSize: 200,
    pageSizeOptions: ['10', '20', '50', '100'],
    onChange: paginate,
    onShowSizeChange: paginate,
    showTotal: (total: number, range: any[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    style: {
      marginTop: '0px',
      padding: '2rem 1rem',
      background: 'white',
      borderRadius: '0 0 4px 4px',
    },
    total: productLibraryCount,
  };

  return viewType === ProductLibraryListViewType.Grid ? (
    <ProductLibraryGridList
      loading={loading}
      productLibrary={productLibrary}
      onLoadMore={onLoadMore}
      hasMore={hasMore}
    />
  ) : (
    <ProductsTable
      totalCount={productLibraryCount}
      dataSource={productLibrary}
      loading={loading}
      hasMore={hasMore}
      headerTitle="Recently Added"
      columns={columns}
      disabledCheckbox
      hasFooterAddButton={productListCount > 0 || selectedProducts.length > 0}
      hasFooterRemoveButton={productListCount > 0}
      paginationSetting={paginationSetting}
      tableHeight="calc(100vh - 250px)"
    />
  );
};

export default memo(ProductLibraryItemsList, propsAreEqual);
