import { Col, Row, Skeleton, Typography } from 'antd';
import { useEffect } from 'react';

import { fetchStatusData } from '../../../store/features/status/statusSlice';
import { getMePermissions, UserPermissions } from '../../../util';
import ViewWrapper from '../../elements/ViewWrapper';
import CategoriesTab from '../MetaDataView/components/CategoriesTab/CategoriesTab';

import { useAppDispatch, useAppSelector } from '../../../store';
import './StatusView.less';

const { Text, Title } = Typography;

const Status = () => {
  const dispatch = useAppDispatch();
  const { loading, statusData } = useAppSelector((state) => state.status);
  const currentUserPermissions = getMePermissions();

  useEffect(() => {
    dispatch(fetchStatusData());
  }, [dispatch]);

  const {
    total_products,
    needs_processing,
    past_24_hours,
    past_30_days,
    past_7_days,
    past_90_days,
  } = statusData?.data || {};

  const renderStatusCard = (title: string, data: any) => (
    <div className="status-card">
      <Typography>{title}</Typography>
      {loading ? (
        <Skeleton active paragraph={{ rows: 3 }} />
      ) : (
        <>
          <div
            className={`status-value ${title.toLowerCase().replace(' ', '-')}`}
          >
            {data?.total}
          </div>
          {Object.entries(data || {}).map(
            ([key, value]) =>
              key !== 'total' && (
                <div key={key} className="status-detail">
                  <Typography>{key.replace('_', ' ')}</Typography>
                  <Text strong>{value as string}</Text>
                </div>
              )
          )}
        </>
      )}
    </div>
  );

  return (
    <ViewWrapper>
      <div className="status-view-wrapper">
        <Row gutter={[16, 16]}>
          <Col span={12}>
            {renderStatusCard('Total Products', total_products)}
          </Col>
          <Col span={12}>
            {renderStatusCard('Needs Processing', needs_processing)}
          </Col>
          <Col span={6}>{renderStatusCard('Past 24 Hours', past_24_hours)}</Col>
          <Col span={6}>{renderStatusCard('Past 7 Days', past_7_days)}</Col>
          <Col span={6}>{renderStatusCard('Past 30 Days', past_30_days)}</Col>
          <Col span={6}>{renderStatusCard('Past 90 Days', past_90_days)}</Col>
        </Row>
        {currentUserPermissions.includes(UserPermissions.MetatDataView) && (
          <div className="category-section">
            <Title level={5}>Products by Category</Title>
            <CategoriesTab hideEdit />
          </div>
        )}
      </div>
    </ViewWrapper>
  );
};

export default Status;
