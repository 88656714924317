import { BASE_API_URL } from '../../config';
import { ListApiResponse, del, get, post } from '../base';
import {
  GetCurrentMediaResponse,
  MediaUploadedRequest,
  MediaUploadedResponse,
  OvrProjectCellMediaFileDeleteRequest,
  SignedUploadUrlResponse,
} from './reqres';

export default function MediaService() {
  return Object.freeze({
    getSignedUploadUrl(
      fileName: string,
      cellUUID: string
    ): Promise<SignedUploadUrlResponse> {
      return get(
        `${BASE_API_URL()}/file-storage/upload-url/ovr_cell?file=${fileName}&uuid=${cellUUID}`
      );
    },
    mediaUploaded(
      mediaData: MediaUploadedRequest
    ): Promise<MediaUploadedResponse> {
      return post(`${BASE_API_URL()}/media/ovr_cell/uploaded`, mediaData);
    },
    getCurrentMedia(productId: string): Promise<GetCurrentMediaResponse> {
      return get(
        `${BASE_API_URL()}/media/item/${productId}/current`
      ) as Promise<ListApiResponse>;
    },
    getOvrProjectCellSignedUploadUrl(
      fileName: string,
      cellUUID: string
    ): Promise<SignedUploadUrlResponse> {
      return get(
        `${BASE_API_URL()}/file-storage/upload-url/ovr_project_cell?file=${fileName}&uuid=${cellUUID}`
      );
    },
    ovrProjectCellMediaUploaded(
      mediaData: MediaUploadedRequest
    ): Promise<MediaUploadedResponse> {
      return post(
        `${BASE_API_URL()}/media/ovr_project_cell/uploaded`,
        mediaData
      );
    },
    deleteOvrProjectCellMediaFile({
      ovrProjectCellId,
      mediaVersionId,
      mediaId,
    }: OvrProjectCellMediaFileDeleteRequest): Promise<any> {
      return del(
        `${BASE_API_URL()}/media/ovr_cell/${ovrProjectCellId}/${mediaVersionId}/file/${mediaId}`
      );
    },
  });
}
