import {
  ListProductLibraryModel,
  ProductMarketEnum,
} from '../../../../models/product-library';
import { formatDate } from '../../../../util';

import './Row.less';

const ProductKeyNameMap: { [productKey: string]: string } = {
  modelname: 'Model Name',
  old_size: 'Size',
  // size_unit: 'Size Unit',
  old_width: 'Width',
  old_height: 'Height',
  old_depth: 'Depth',
  old_quality: 'Quality',
  old_model_date: 'Model Date',
  old_modified_date: 'Model Last Modified Date',
  old_id: 'Old WBML ID',
  market: 'Market',
  old_vr_notes: 'VR Notes',
  reuploaded: 'Re Uploaded',
  old_weight: 'Weight',
  old_count: 'Count',
  // count_unit: 'Count Unit',
  old_tag_item: 'Tag Item',
  old_wblm_id: 'WBLM ID',
  old_private: 'Private',
  old_flex: 'Flex',
  old_item_master: 'Item Master',
  format: 'Format',
  upc: 'UPC',
  name: 'Item Name',
  brand: 'Brand',
};

interface RowProps {
  productKey?: string;
  label?: string;
  data: string | number;
  unitName?: string;
}

export const Row = ({ productKey, label, data, unitName }: RowProps) => {
  if (label === 'Width' || label === 'Height' || label === 'Depth') {
    data = `${data} in`;
  }
  if (label === 'Count' || label === 'Size') {
    if (unitName) {
      data = `${data} ${unitName}`;
    }
  }

  return (
    <>
      <div className="row">
        <div className="row-item">
          {productKey ? (
            <p className="row-label">{ProductKeyNameMap[productKey]}</p>
          ) : (
            <p className="row-label">{label}</p>
          )}
          <p className="row-data">{data}</p>
          <hr />
        </div>
      </div>
    </>
  );
};

export enum ProductFieldType {
  Property = 'property',
}

export const renderField = (
  product: ListProductLibraryModel,
  productKey: string,
  type?: string,
  unitName?: string
) => {
  if (productKey === 'old_modified_date') {
    const modifiedDate = product?.media_files?.[0]?.created_at
      ? formatDate(product?.media_files?.[0]?.created_at)
      : 'N/A';
    return (
      <>
        <Row label={ProductKeyNameMap[productKey]} data={modifiedDate} />
      </>
    );
  }

  // Render Properties
  if (type === ProductFieldType.Property) {
    if (productKey === 'brand') {
      return (
        <>
          {product[productKey] ? (
            <Row
              label={ProductKeyNameMap[productKey]}
              data={product[productKey].name}
            />
          ) : (
            <Row productKey={productKey} data="N/A" />
          )}
        </>
      );
    }
    return (
      <>
        {product[productKey] ? (
          <Row
            label={ProductKeyNameMap[productKey]}
            data={product[productKey]}
          />
        ) : (
          <Row productKey={productKey} data="N/A" />
        )}
      </>
    );
  }

  // Render Fields
  const fieldValue = product.fields.find(
    (field) => field.field_key === productKey
  );

  // No Field Value
  if (!fieldValue) {
    return <Row productKey={productKey} data="N/A" />;
  }

  // Market
  if (productKey === 'market' && typeof fieldValue?.value === 'number') {
    return (
      <>
        {fieldValue ? (
          <Row
            label={fieldValue?.name}
            data={ProductMarketEnum[fieldValue?.value]}
          />
        ) : (
          <Row productKey={productKey} data="N/A" />
        )}
      </>
    );
  }

  // Count
  if (productKey === 'old_count' || productKey === 'old_size') {
    return (
      <>
        {fieldValue ? (
          <Row
            label={fieldValue?.name}
            data={fieldValue?.value}
            unitName={unitName}
          />
        ) : (
          <Row productKey={productKey} data="N/A" />
        )}
      </>
    );
  }

  // Re Uploaded
  if (productKey === 'reuploaded') {
    return (
      <>
        {fieldValue ? (
          <Row
            label={fieldValue?.name}
            data={fieldValue?.value === '1' ? 'Yes' : 'No'}
          />
        ) : (
          <Row productKey={productKey} data="N/A" />
        )}
      </>
    );
  }

  // Notes
  if (productKey === 'master_librarian_notes') {
    return (
      <>
        {fieldValue ? (
          <Row label="" data={fieldValue?.value} />
        ) : (
          <Row productKey={productKey} data="N/A" />
        )}
      </>
    );
  }

  return (
    <>
      <Row label={fieldValue?.name} data={fieldValue?.value} />
    </>
  );
};
