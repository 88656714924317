export const debounce = (func: Function, wait: number) => {
  let timeout: any;
  return function (...args: any) {
    const later = () => {
      timeout = null;
      func.apply(null, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};
