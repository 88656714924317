import { FC, memo, useCallback, useMemo } from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Space,
  Switch,
  Typography,
} from 'antd';
import { History, Location } from 'history';
import { withRouter } from 'react-router-dom';

import { ApiError, handleError } from '../../../../api/base';
import {
  CountUnitModel,
  initialNewCountUnitModel,
} from '../../../../models/count-unit';
import { ProductStatusEnum } from '../../../../models/product-library';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  createCustomFieldOption,
  deleteCustomFieldOption,
  fetchCountUnits,
  updateCustomFieldOption,
} from '../../../../store/features/metaData/metaDataSlice';
import {
  getMePermissions,
  propsAreEqual,
  UserPermissions,
} from '../../../../util';
import FormWrapper from '../../../elements/FormWrapper';
import { RoutePath } from '../../../views/AppRoot/types';
import './CountUnitForm.less';
import { CountUnitFormLocationState } from './types';

interface CountUnitFormProps {
  history: History;
  location: Location<CountUnitFormLocationState>;
}

interface CountUnitProps {
  name: string;
  status: string;
  in_library: number;
  in_processing: number;
}

const CountUnitForm: FC<CountUnitFormProps> = (props) => {
  const { history, location } = props;
  const dispatch = useAppDispatch();
  const { deletingCustomFieldOption, savingCustomFieldOption } = useAppSelector(
    (state) => state.metaData
  );

  const [form] = Form.useForm();
  const isNewCountUnit = useMemo(() => !location.state, [location.state]);
  const { in_library, in_processing, in_use } = {
    ...location?.state?.data,
  };
  const { data: countUnit } = { ...location?.state };

  const fields = useMemo(
    () =>
      Object.keys(
        isNewCountUnit ? initialNewCountUnitModel : location.state?.data
      ),
    [isNewCountUnit, location.state?.data]
  );

  const currentUserPermissions = useMemo(
    (): string[] => getMePermissions(),
    []
  );

  const onError = useCallback((err: ApiError) => {
    handleError(err);
  }, []);

  const handleDeleteCountUnit = useCallback(
    async (countUnit: CountUnitModel) => {
      message.loading(
        {
          content: 'Deleting count unit...',
          key: 'deleting-count-unit-message',
        },
        0
      );

      const res: any = await dispatch(
        deleteCustomFieldOption({
          customFieldKey: 'count_unit',
          optionId: location.state?.data.id,
        })
      );
      if (res.error) {
        onError({ error: res.payload.error });
      } else {
        message.destroy('deleting-count-unit-message');
        await dispatch(fetchCountUnits());
        message.success(`Count unit '${countUnit.name}' deleted.`);
        history.goBack();
      }
    },
    [dispatch, location.state?.data.id, onError, history]
  );

  const handleConfirmDeleteCountUnit = useCallback(
    (e: React.MouseEvent, countUnit: CountUnitModel) => {
      e.preventDefault();
      Modal.confirm({
        title: `Are you sure you want to delete the count unit "${countUnit.name}"?`,
        icon: <ExclamationCircleOutlined />,
        content: `You will no longer be able to add products to the Count Unit "${countUnit.name}" after it has been deleted.`,
        okType: 'danger',
        okText: 'Delete',
        onOk: () => handleDeleteCountUnit(countUnit),
      });
    },
    [handleDeleteCountUnit]
  );

  const saveCountUnit = useCallback(
    async (countUnit: CountUnitModel) => {
      const { name, statusCheckValue } = countUnit;

      let status: string;
      let newCountUnit: CountUnitProps = {
        name: '',
        status: '',
        in_library: 0,
        in_processing: 0,
      };

      if (statusCheckValue) {
        status = ProductStatusEnum.approved;
      } else {
        status = ProductStatusEnum.needs_approval;
      }

      newCountUnit = {
        name,
        status,
        in_library: location.state?.data.in_library,
        in_processing: location.state?.data.in_processing,
      };

      const res: any = isNewCountUnit
        ? await dispatch(
            createCustomFieldOption({
              customFieldKey: 'count_unit',
              customFieldOption: newCountUnit,
            })
          )
        : await dispatch(
            updateCustomFieldOption({
              customFieldKey: location.state?.data
                .custom_field_id as unknown as string,
              optionId: location.state?.data.id,
              customFieldOption: newCountUnit,
            })
          );

      if (res.error) {
        onError({ error: res.payload.error });
      } else {
        message.success('Count Unit saved.');
        await dispatch(fetchCountUnits());
        history.goBack();
      }
    },
    [
      dispatch,
      history,
      isNewCountUnit,
      location.state?.data.custom_field_id,
      location.state?.data.id,
      location.state?.data.in_library,
      location.state?.data.in_processing,
      onError,
    ]
  );

  const onSubmit = useCallback(
    (values: CountUnitModel) => {
      form.validateFields(fields).then(() => saveCountUnit(values));
    },
    [fields, form, saveCountUnit]
  );

  const getInitialValues = () => {
    if (isNewCountUnit) {
      return { ...initialNewCountUnitModel };
    }
    const { status } = location.state.data;
    return {
      ...location.state.data,
      statusCheckValue: status === ProductStatusEnum.approved,
    };
  };

  return (
    <FormWrapper
      title={`${isNewCountUnit ? 'Add' : 'Edit'} Count Unit of Measurement`}
      onClose={() => history.push(RoutePath.MetaData)}
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={getInitialValues()}
        onFinish={onSubmit}
      >
        <Form.Item
          label="Count Unit of Measurement"
          name="name"
          rules={[
            {
              required: true,
              message: 'Count Unit of Measurement name is required.',
            },
          ]}
        >
          <Input
            data-cy="count-unit-form-input"
            placeholder="Count Unit of Measurement name"
          />
        </Form.Item>
        <Form.Item label="Status">
          <div className="count-unit-form-status">
            <Typography>Librarian Approved</Typography>
            <Form.Item name="statusCheckValue" valuePropName="checked">
              <Switch
                disabled={
                  !currentUserPermissions.includes(
                    UserPermissions.MetaDataApprove
                  )
                }
              />
            </Form.Item>
          </div>
        </Form.Item>
        {!isNewCountUnit ? (
          <Form.Item label="Details">
            <div className="count-unit-form-details">
              <Typography>Number of models in library</Typography>
              <Typography>{in_library}</Typography>
            </div>
            <div className="count-unit-form-details">
              <Typography>
                Number of models in processing / master librarian list
              </Typography>
              <Typography>{in_processing}</Typography>
            </div>
          </Form.Item>
        ) : null}
        <Form.Item>
          {isNewCountUnit ? (
            <Space style={{ float: 'right' }}>
              <Button onClick={() => history.goBack()}>Cancel</Button>
              <Button
                loading={savingCustomFieldOption}
                data-cy="count-unit-form-submit-btn"
                htmlType="submit"
                type="primary"
                disabled={
                  !currentUserPermissions.includes(
                    UserPermissions.MetaDataCreate
                  )
                }
              >
                Add
              </Button>
            </Space>
          ) : (
            <Space style={{ float: 'right' }}>
              {!in_use ? (
                <Button
                  danger
                  loading={deletingCustomFieldOption}
                  type="primary"
                  onClick={(e: React.MouseEvent) =>
                    handleConfirmDeleteCountUnit(e, countUnit as CountUnitModel)
                  }
                  disabled={
                    !currentUserPermissions.includes(
                      UserPermissions.MetaDataDelete
                    )
                  }
                >
                  Delete
                </Button>
              ) : null}
              <Button
                loading={savingCustomFieldOption}
                type="primary"
                htmlType="submit"
                disabled={
                  !currentUserPermissions.includes(UserPermissions.MetaDataEdit)
                }
              >
                Save
              </Button>
            </Space>
          )}
        </Form.Item>
      </Form>
    </FormWrapper>
  );
};

export default withRouter<any, any>(memo(CountUnitForm, propsAreEqual));
