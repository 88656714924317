export interface SizeUnitModel {
  id: number;
  custom_field_id: number;
  name: string;
  option_key: string;
  created_at: string;
  updated_at: string;
  visible: number;
  status: string;
  parent_id: number | null;
  deleted_at?: string | null;
  media_files: any[];
  in_library: number;
  in_processing: number;
  in_use: boolean;
  statusCheckValue?: boolean | undefined;
}

export const initialNewSizeUnitModel: Partial<SizeUnitModel> = {
  name: '',
};
