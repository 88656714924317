import { useCallback, useEffect, useMemo, useState } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import {
  BackTop,
  Button,
  Image,
  Input,
  Space,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';

import imagePlaceholder from '../../../assets/images/product-image-placeholder.png';
import useDebounce from '../../../hooks/useDebounce';
import { ListProductLibraryModel } from '../../../models/product-library';
import {
  paginateProductList,
  removeProductItemFromList,
  removeSelectedProductItemsFromList,
  searchProductsByName,
} from '../../../store/features/productList/productListSlice';
import { useAppDispatch, useAppSelector } from '../../../store/index';
import { formatDate } from '../../../util';
import DropdownMenu from '../../containers/DropdownMenu';
import ProductsTable from '../../containers/Tables/ProductsTable';
import NoResultsFound from '../../elements/NoResultsFound';
import ViewWrapper from '../../elements/ViewWrapper';
import './ProductListView.less';

const antSpinIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;

const { Search } = Input;
const { Text } = Typography;

interface SearchBarProps {
  searchingProducts: boolean;
  searchQuery: string;
  updateSearchQuery: (query: string) => void;
  productList: ListProductLibraryModel[];
}

const SearchBar = ({
  searchingProducts,
  searchQuery,
  updateSearchQuery,
  productList,
}: SearchBarProps) => {
  const dispatch = useAppDispatch();

  return (
    <div className="product-list__search-bar">
      <div className="product-list__search-bar-left">
        <Search
          className="product-list__search-input"
          allowClear={!searchingProducts}
          value={searchQuery}
          onChange={(e) => updateSearchQuery(e.target.value)}
          enterButton
          placeholder="Search for products..."
          suffix={searchingProducts && <Spin indicator={antSpinIcon} />}
        />
      </div>
      <div className="product-list__search-bar-right">
        <Space size="middle">
          <Button
            danger
            type="primary"
            onClick={() =>
              dispatch(removeSelectedProductItemsFromList(productList))
            }
          >
            Remove All
          </Button>
          <DropdownMenu requireSelectedProduct={true} />
        </Space>
      </div>
    </div>
  );
};

const ProductListView = () => {
  const dispatch = useAppDispatch();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const debouncedSearchQuery = useDebounce<string>(searchQuery, 500);
  const {
    value: productList,
    fetchingProductListItems,
    searchingProductList,
    allProductListItems,
    totalCount,
  } = useAppSelector((state) => state.productList);

  const hasNoSearchResultsFound = useMemo(
    () =>
      !fetchingProductListItems && !!searchQuery && productList.length === 0,
    [fetchingProductListItems, searchQuery, productList.length]
  );

  const hasProductListItems = !(
    !fetchingProductListItems && allProductListItems.length === 0
  );

  useEffect(() => {
    dispatch(searchProductsByName(debouncedSearchQuery));
  }, [debouncedSearchQuery, dispatch]);

  const updateSearchQuery = useCallback((query: string) => {
    setSearchQuery(query);
  }, []);

  const columns: ColumnsType<ListProductLibraryModel> = useMemo(
    () => [
      {
        title: 'Model',
        dataIndex: 'data',
        key: 'model',
        width: 100,
        render: (_, productItem) => {
          return (
            <div className="center-cell">
              <Link
                to={{
                  pathname: `/product-library/${productItem.uuid}`,
                  state: {
                    productItem,
                  },
                }}
              >
                <Image
                  style={{
                    width: '100%',
                    maxHeight: '130px',
                    objectFit: 'cover',
                  }}
                  src={
                    productItem.image_url
                      ? productItem.image_url
                      : imagePlaceholder
                  }
                  alt={`${productItem.name}`}
                  preview={false}
                />
              </Link>
            </div>
          );
        },
      },
      {
        title: 'UPC',
        dataIndex: 'upc',
        key: 'upc',
        width: 150,
        render: (upc: string) =>
          !!upc ? (
            <Tooltip title={upc} mouseEnterDelay={1}>
              <Text ellipsis style={{ maxWidth: '400px' }}>
                {upc}
              </Text>
            </Tooltip>
          ) : (
            <Text type="secondary" style={{ fontStyle: 'italic' }}>
              N/A
            </Text>
          ),
        sorter: (a: ListProductLibraryModel, b: ListProductLibraryModel) =>
          Number(a?.upc?.replace(/\D/g, '')) -
          Number(b?.upc?.replace(/\D/g, '')),
      },
      {
        title: 'Product Name',
        dataIndex: 'name',
        key: 'name',
        width: 150,
        render: (productName: string) =>
          !!productName ? (
            <Tooltip title={productName} mouseEnterDelay={1}>
              <Text style={{ maxWidth: '150px' }}>{productName}</Text>
            </Tooltip>
          ) : (
            <Text type="secondary" style={{ fontStyle: 'italic' }}>
              No product name.
            </Text>
          ),
        sorter: (a: ListProductLibraryModel, b: ListProductLibraryModel) =>
          a?.name?.localeCompare(b?.name),
      },
      {
        title: 'Brand',
        dataIndex: 'brand_name',
        key: 'brand',
        width: 100,
        render: (brand: string) =>
          !!brand ? (
            <Tooltip title={brand} mouseEnterDelay={1}>
              <Text style={{ maxWidth: '150px' }}>{brand}</Text>
            </Tooltip>
          ) : (
            <Text type="secondary" style={{ fontStyle: 'italic' }}>
              No brand.
            </Text>
          ),

        sorter: (a: ListProductLibraryModel, b: ListProductLibraryModel) =>
          a.brand?.name.localeCompare(b.brand?.name),
      },
      {
        title: 'Manufacturer',
        dataIndex: 'manufacturer',
        key: 'manufacturer',
        width: 150,
        render: (manufacturer: string) =>
          !!manufacturer ? (
            <Tooltip title={manufacturer} mouseEnterDelay={1}>
              <Text ellipsis style={{ maxWidth: '400px' }}>
                {manufacturer}
              </Text>
            </Tooltip>
          ) : (
            <Text type="secondary" style={{ fontStyle: 'italic' }}>
              No manufacturer.
            </Text>
          ),
        sorter: (a: ListProductLibraryModel, b: ListProductLibraryModel) =>
          a.brand?.manufacturer?.name.localeCompare(
            b.brand?.manufacturer?.name
          ),
      },
      {
        title: 'Size',
        dataIndex: 'size',
        key: 'size',
        width: 100,
        render: (size: number) =>
          !!size ? (
            <Tooltip title={size} mouseEnterDelay={1}>
              <Text style={{ maxWidth: '150px' }}>{size}</Text>
            </Tooltip>
          ) : (
            <Text type="secondary" style={{ fontStyle: 'italic' }}>
              No size.
            </Text>
          ),
        sorter: (a: ListProductLibraryModel, b: ListProductLibraryModel) => {
          // For product items that are null/undefined
          const aSize = `${a.size}`?.split(' ')?.[0];
          const bSize = `${b.size}`?.split(' ')?.[0];
          if (aSize && bSize) {
            return Number(aSize) - Number(bSize);
          } else if (aSize) {
            return 1;
          } else if (bSize) {
            return -1;
          } else {
            return 0;
          }
        },
      },
      {
        title: 'Count',
        dataIndex: 'count',
        key: 'count',
        width: 100,
        render: (count: string) =>
          !!count ? (
            <Tooltip title={count} mouseEnterDelay={1}>
              <Text style={{ maxWidth: '150px' }}>{count}</Text>
            </Tooltip>
          ) : (
            <Text type="secondary" style={{ fontStyle: 'italic' }}>
              No count.
            </Text>
          ),
        sorter: (a: ListProductLibraryModel, b: ListProductLibraryModel) => {
          const aCount = a.count?.split(' ')?.[0];
          const bCount = b.count?.split(' ')?.[0];
          if (aCount && bCount) {
            return Number(aCount) - Number(bCount);
          } else if (aCount) {
            return 1;
          } else if (bCount) {
            return -1;
          } else {
            return 0;
          }
        },
      },
      {
        title: 'Modeled Date',
        dataIndex: 'created_at',
        key: 'created_at',
        width: 150,
        defaultSortOrder: 'ascend',
        render: (date: string) => {
          return <div>{date ? formatDate(date) : null}</div>;
        },
        sorter: (a: ListProductLibraryModel, b: ListProductLibraryModel) =>
          new Date(b?.created_at).getTime() - new Date(a?.created_at).getTime(),
      },
      {
        title: 'Actions',
        dataIndex: 'data',
        key: 'actions',
        width: 100,
        render: (_, productItem: ListProductLibraryModel) => {
          return (
            <Space direction="horizontal" size="small">
              <Tooltip title="Remove from Product List">
                <Button
                  className="link-button"
                  type="link"
                  onClick={async () => {
                    await dispatch(removeProductItemFromList(productItem.uuid));
                    dispatch(searchProductsByName(debouncedSearchQuery));
                  }}
                >
                  Remove
                </Button>
              </Tooltip>
            </Space>
          );
        },
      },
    ],
    [debouncedSearchQuery, dispatch]
  );

  const paginate = useCallback(
    (page: number, pageSize: number) =>
      dispatch(
        paginateProductList({ page, pageSize, queryStr: debouncedSearchQuery })
      ),
    [debouncedSearchQuery, dispatch]
  );

  return (
    <ViewWrapper>
      <div className="product-list__search-bar-container">
        <SearchBar
          searchQuery={searchQuery}
          searchingProducts={searchingProductList}
          updateSearchQuery={updateSearchQuery}
          productList={productList}
        />
      </div>
      <div className="product-list__content">
        {hasProductListItems && hasNoSearchResultsFound ? (
          <NoResultsFound
            searchQuery={searchQuery}
            onClear={() => updateSearchQuery('')}
          />
        ) : (
          <ProductsTable
            totalCount={totalCount}
            paginate={paginate}
            loading={fetchingProductListItems}
            dataSource={productList}
            columns={columns}
            headerTitle="Products List"
            hasFooterRemoveButton
            hasFooterBulkEditButton
            tableHeight="calc(100vh - 250px)"
          />
        )}
      </div>

      <BackTop />
    </ViewWrapper>
  );
};

export default ProductListView;
