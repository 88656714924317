import { DownloadOutlined } from '@ant-design/icons';
import { Button, Progress, Typography } from 'antd';
import { CronJobModel } from '../../../models/cron-job';
import { convertDate } from '../../../util';

import './DownloadItem.less';

interface DownloadItemProps {
  cronJob: CronJobModel;
  handleDeleteClick: () => void;
  handleCancelClick: () => void;
  handleDownloadClick: () => void;
}

const { Text } = Typography;

const DownloadItem = ({
  cronJob,
  handleDeleteClick,
  handleCancelClick,
  handleDownloadClick,
}: DownloadItemProps) => {
  const { status, file_type, progress, created_at } = cronJob;

  let fileNameDisplay = '';
  if (file_type === 'front_png') {
    fileNameDisplay = 'Front PNG';
  } else if (file_type === 'png') {
    fileNameDisplay = 'All PNG';
  } else if (file_type === 'obj') {
    fileNameDisplay = 'RSG';
  } else {
    fileNameDisplay = file_type?.toUpperCase();
  }

  const statusUpperCase = status?.charAt(0).toUpperCase() + status?.slice(1);

  return (
    <div className="download-item">
      <div className="download-item-content">
        <div className="download-item-header">
          <Text className="status-text">
            {`${fileNameDisplay} ${statusUpperCase}...`}
          </Text>
          <Text className="date-text">
            {created_at ? convertDate(created_at) : null}
          </Text>
        </div>
        <div
          style={{
            width: '100%',
            marginRight: '1rem',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div className="download-item-body">
            <Progress
              percent={progress}
              showInfo={false}
              strokeColor="#8e24aa"
              trailColor="#f0f0f0"
            />
            <div className="download-item-footer">
              {progress === 100 ? (
                <Text
                  onClick={handleDeleteClick}
                  className="delete-text"
                  underline
                >
                  Delete
                </Text>
              ) : (
                <Text
                  onClick={handleCancelClick}
                  className="cancel-text"
                  underline
                >
                  Cancel
                </Text>
              )}
              <Text strong className="progress-text">
                {progress}%
              </Text>
            </div>
          </div>
          <Button
            className="download-button"
            type="primary"
            icon={<DownloadOutlined />}
            size="small"
            onClick={handleDownloadClick}
          />
        </div>
      </div>
    </div>
  );
};

export default DownloadItem;
