import { LoadingOutlined } from '@ant-design/icons';
import { Button, Input, Spin } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import './SearchBar.less';

const { Search } = Input;

const antSpinIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;

interface SearchBarProps {
  searchQuery: string;
  updateSearchQuery: (query: string) => void;
  isSearching: boolean;
  canCreate: boolean;
  createLink: string;
  createButtonText: string;
  placeholder?: string;
}

const SearchBar: React.FC<SearchBarProps> = ({
  searchQuery,
  updateSearchQuery,
  isSearching,
  canCreate,
  createLink,
  createButtonText,
  placeholder = 'Search',
}) => (
  <div className="search-bar">
    <div className="search-bar-left">
      <Search
        className="search-input"
        allowClear={!isSearching}
        value={searchQuery}
        onChange={(e) => updateSearchQuery(e.target.value)}
        enterButton
        placeholder={placeholder}
        suffix={isSearching && <Spin indicator={antSpinIcon} />}
      />
    </div>
    {canCreate && (
      <Link to={createLink}>
        <Button type="primary">{createButtonText}</Button>
      </Link>
    )}
  </div>
);

export default SearchBar;
