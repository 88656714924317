import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ClientService from '../../../api/client';
import { ClientModel } from '../../../models/client';
import { convertParamsToQuery } from '../../../util';

interface ClientDropdownState {
  clients: ClientModel[];
  loading: boolean;
  error: string | null;
  pagination: {
    limit: number;
    offset: number;
    count: number;
  };
}

const initialState: ClientDropdownState = {
  clients: [],
  loading: false,
  error: null,
  pagination: {
    limit: 100,
    offset: 0,
    count: 0,
  },
};

export const fetchDropdownClients = createAsyncThunk(
  'clientDropdown/fetchClients',
  async ({ offset = 0 }: { offset?: number }, { rejectWithValue }) => {
    try {
      const params = {
        _order_by: 'created_at:desc',
        _limit: 100,
        _offset: offset,
      };

      const queryString = convertParamsToQuery(params);
      const resp = await ClientService().listClients(queryString);
      return resp;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const clientDropdownSlice = createSlice({
  name: 'clientDropdown',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDropdownClients.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDropdownClients.fulfilled, (state, action) => {
        state.clients = [...state.clients, ...action.payload.data];
        state.pagination = action.payload.pagination;
        state.loading = false;
      })
      .addCase(fetchDropdownClients.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'An error occurred';
      });
  },
});

export default clientDropdownSlice;
