import { BASE_API_URL } from '../../config';
import { ListApiResponse, del, get, patch, post } from '../base';
import {
  CountUnitsResponse,
  CreateCustomFieldOptionRequest,
  CustomFieldFormOptionsResponse,
  CustomFieldOptionResponse,
  CustomFieldOptionsResponse,
  CustomFieldResponse,
  CustomFieldsResponse,
  FormatsResponse,
  MarketsResponse,
  SizeUnitsResponse,
  TagsResponse,
  UpdateCustomFieldOptionRequest,
} from './reqres';

export default function MetaDataService() {
  return Object.freeze({
    listCustomFields(query?: string): Promise<CustomFieldsResponse> {
      return get(`${BASE_API_URL()}/field${query}`) as Promise<ListApiResponse>;
    },
    getCustomField(customFieldKey: string): Promise<CustomFieldResponse> {
      return get(`${BASE_API_URL()}/field/${customFieldKey}`);
    },
    getTags(): Promise<TagsResponse> {
      return get(
        `${BASE_API_URL()}/meta-data/tags`
      ) as Promise<ListApiResponse>;
    },
    searchTagsByName(query: string): Promise<TagsResponse> {
      return get(
        `${BASE_API_URL()}/meta-data/tags${query}`
      ) as Promise<ListApiResponse>;
    },
    listCustomFieldOptionsByKey(
      customFieldKey: string,
      query?: string
    ): Promise<CustomFieldOptionsResponse> {
      return get(
        `${BASE_API_URL()}/field/${customFieldKey}/option${query}`
      ) as Promise<ListApiResponse>;
    },
    getCustomFieldOption(
      customFieldKey: string,
      optionId: number
    ): Promise<CustomFieldOptionResponse> {
      return get(
        `${BASE_API_URL()}/field/${customFieldKey}/option/${optionId}`
      );
    },
    createCustomFieldOption(
      customFieldKey: string,
      req: CreateCustomFieldOptionRequest
    ): Promise<CustomFieldOptionResponse> {
      return post(`${BASE_API_URL()}/field/${customFieldKey}/option`, req);
    },
    updateCustomFieldOption(
      customFieldKey: string,
      optionId: number,
      req: UpdateCustomFieldOptionRequest
    ): Promise<CustomFieldOptionResponse> {
      return patch(
        `${BASE_API_URL()}/field/${customFieldKey}/option/${optionId}`,
        req
      );
    },
    deleteCustomFieldOption(
      customFieldKey: string,
      optionId: number
    ): Promise<CustomFieldOptionResponse> {
      return del(
        `${BASE_API_URL()}/field/${customFieldKey}/option/${optionId}`
      );
    },
    getCustomFieldFormOptions(): Promise<CustomFieldFormOptionsResponse> {
      return get(
        `${BASE_API_URL()}/values/custom_field_value_type,custom_field_type`
      );
    },
    getFormats(): Promise<FormatsResponse> {
      return get(
        `${BASE_API_URL()}/meta-data/format`
      ) as Promise<ListApiResponse>;
    },
    searchFormatsByName(query: string): Promise<FormatsResponse> {
      return get(
        `${BASE_API_URL()}/meta-data/format${query}`
      ) as Promise<ListApiResponse>;
    },
    getMarkets(): Promise<MarketsResponse> {
      return get(
        `${BASE_API_URL()}/meta-data/market`
      ) as Promise<ListApiResponse>;
    },
    searchMarketsByName(query: string): Promise<MarketsResponse> {
      return get(
        `${BASE_API_URL()}/meta-data/market${query}`
      ) as Promise<ListApiResponse>;
    },
    getCountUnits(): Promise<CountUnitsResponse> {
      return get(
        `${BASE_API_URL()}/meta-data/count_unit`
      ) as Promise<ListApiResponse>;
    },
    searchCountUnitsByName(query: string): Promise<CountUnitsResponse> {
      return get(
        `${BASE_API_URL()}/meta-data/count_unit${query}`
      ) as Promise<ListApiResponse>;
    },
    getSizeUnits(): Promise<SizeUnitsResponse> {
      return get(
        `${BASE_API_URL()}/meta-data/size_unit`
      ) as Promise<ListApiResponse>;
    },
    searchSizeUnitsByName(query: string): Promise<SizeUnitsResponse> {
      return get(
        `${BASE_API_URL()}/meta-data/size_unit${query}`
      ) as Promise<ListApiResponse>;
    },
  });
}
