import { CronJobModel } from '../../../models/cron-job';
import { useAppDispatch } from '../../../store';
import { cancelOrDeleteDownload } from '../../../store/features/modelDownload/modelDownloadSlice';
import DownloadItem from './DownloadItem';

import './DownloadItemContainer.less';

export interface DownloadItemContainerProps {
  cronJob: CronJobModel;
}

const DownloadItemContainer = ({ cronJob }: DownloadItemContainerProps) => {
  const dispatch = useAppDispatch();
  const { result, status } = cronJob;

  const handleDownload = () => {
    const downloadLinkEl = document.createElement('a');
    downloadLinkEl.href = result?.download_url ? result?.download_url : '';
    downloadLinkEl.click();
    downloadLinkEl.remove();
  };

  const handleCancelOrDeleteDownload = () => {
    dispatch(cancelOrDeleteDownload({ cronJobId: cronJob?.job_id }));
  };

  return (
    <>
      <hr />
      <div className="download-item-container">
        {status === 'completed' && (
          <DownloadItem
            cronJob={cronJob}
            handleDeleteClick={handleCancelOrDeleteDownload}
            handleCancelClick={handleCancelOrDeleteDownload}
            handleDownloadClick={handleDownload}
          />
        )}
      </div>
    </>
  );
};

export default DownloadItemContainer;
