export interface TagModel {
  id?: number;
  custom_field_id?: number;
  name: string;
  option_key?: string;
  created_at?: string;
  updated_at?: string;
  status?: string;
  visible?: number;
  parent_id?: number | null;
  deleted_at?: string | null;
  media_files?: any[];
  in_processing?: number;
  in_library?: number;
  in_use?: boolean;
  statusCheckValue?: boolean | undefined;
}

export const initialNewTagModel = {
  name: '',
};
