import { useCallback, useEffect, useState } from 'react';
import { SelectDataItem } from '../components/elements/MultiLevelSelect/types';

export const useMultiLevelSelect = (
  data: SelectDataItem[],
  selected: string | undefined,
  onChange: (selected: SelectDataItem | 'All' | undefined) => void
) => {
  const [selectedItem, setSelectedItem] = useState<
    SelectDataItem | 'All' | undefined
  >();
  const [currentMenuItems, setCurrentMenuItems] = useState<SelectDataItem[]>(
    []
  );
  const [parentItem, setParentItem] = useState<SelectDataItem>();

  const getFlatItemList = useCallback(
    (items: SelectDataItem[]): SelectDataItem[] => {
      return items.reduce((acc: SelectDataItem[], item) => {
        acc.push(item);
        if (item.children) {
          acc.push(...getFlatItemList(item.children));
        }
        return acc;
      }, []);
    },
    []
  );

  useEffect(() => {
    setCurrentMenuItems(data);
  }, [data]);

  useEffect(() => {
    const list = getFlatItemList(data);
    const currentMenu = list.find((item) => item.key === selected);
    setSelectedItem(currentMenu);
  }, [data, getFlatItemList, selected]);

  const handleMenuClick = (key: string) => {
    console.log('key', key);
    const currentMenu = currentMenuItems.find((item) => item.key === key);

    if (currentMenu?.children) {
      setCurrentMenuItems(currentMenu.children);
      setParentItem(currentMenu);
      setSelectedItem(undefined);
      onChange(undefined);
    } else {
      setSelectedItem(currentMenu);
      onChange(currentMenu);
    }
  };

  const handleViewAllBtnClick = () => {
    if (parentItem) {
      setSelectedItem(parentItem);
      onChange(parentItem);
    } else {
      setSelectedItem('All');
      onChange('All');
    }
  };

  const handleBackBtnClick = () => {
    if (parentItem?.parent_id === 0) {
      setCurrentMenuItems(data);
      setParentItem(undefined);
    } else {
      const list = getFlatItemList(data);
      const parent = list.find((item) => parentItem?.parent_id === item.key);
      setCurrentMenuItems(parent?.children as SelectDataItem[]);
      setParentItem(parent);
    }
  };

  const getParent = (itemKey: string): SelectDataItem | undefined => {
    const list = getFlatItemList(data);
    return list.find((item) => item.key === itemKey);
  };

  return {
    selectedItem,
    currentMenuItems,
    parentItem,
    handleMenuClick,
    handleViewAllBtnClick,
    handleBackBtnClick,
    getParent,
  };
};
