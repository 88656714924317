import { ApiError, handleError } from '../../../../api/base';
import CustomFieldService from '../../../../api/custom-field';
import FiltersService from '../../../../api/filters';
import { CustomFieldOption } from '../../../../models/custom-field';
import {
  DrcType,
  ListProductLibraryModel,
} from '../../../../models/product-library';
import { convertParamsToQuery } from '../../../../util';

interface FindFieldValueProps {
  productItem: ListProductLibraryModel;
  key: string;
  defaultValue: string | number | null;
}

interface CheckIfTagExistsProp {
  currentProductTags: CustomFieldOption[] | null;
  submittedTag: string;
}

interface DetermineOldDrcValueProps {
  drcChecked: boolean;
  drcItems: DrcType[];
}

export const findFieldValue = ({
  productItem,
  key,
  defaultValue,
}: FindFieldValueProps): string | number | null => {
  const field = productItem?.fields?.find((field) => field?.field_key === key);
  return field?.value || defaultValue;
};

export const fetchResource = async (resourceType: string) => {
  try {
    const response = await CustomFieldService().getCustomField(resourceType);
    return response.data.options;
  } catch (error) {
    handleError(error as ApiError);
    return null;
  }
};

export const fetchFieldOptions = async (customFieldKey: string) => {
  try {
    const params = {
      _limit: 200,
      _offset: 0,
      status: 'approved',
    };

    const query = convertParamsToQuery(params);

    const res = await CustomFieldService().listCustomFieldOptionsByKey(
      customFieldKey,
      query
    );
    return res.data;
  } catch (error) {
    handleError(error as ApiError);
    return null;
  }
};

export const fetchCategoryFilters = async () => {
  try {
    const response = await FiltersService().getFilters();
    console.log('response data category filters', response.data.categories);
    return response.data.categories || [];
  } catch (error) {
    handleError(error as ApiError);
    return null;
  }
};

export const extractBrandId = (brand_id: {
  key: string | null;
  label: string;
}) => {
  // Check if brand_id is an object and has a key property
  if (brand_id && typeof brand_id === 'object' && brand_id.key) {
    return brand_id.key;
  }
  // If brand_id is a string, return it as is
  else if (typeof brand_id === 'string') {
    return brand_id;
  } else {
    return null;
  }
};

export const checkIfTagExists = ({
  currentProductTags,
  submittedTag,
}: CheckIfTagExistsProp) => {
  return (
    currentProductTags?.some(
      (tag: CustomFieldOption) =>
        tag.option_key === submittedTag || tag.name === submittedTag
    ) || false
  );
};
export const determineOldDrcValue = ({
  drcChecked,
  drcItems,
}: DetermineOldDrcValueProps) => {
  return !(drcItems.length === 0 || !drcChecked);
};

export const convertToSnakeCase = (strArray: string[]) => {
  return strArray.map((str) =>
    str
      .split(/\W+|(?=[A-Z])/)
      .map((part) => part.toLowerCase())
      .join('_')
  );
};
