import { useCallback, useEffect, useMemo } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { BackTop, Image, Input, Spin, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';

import imagePlaceholder from '../../../assets/images/product-image-placeholder.png';
import useDebounce from '../../../hooks/useDebounce';
import {
  ListProductLibraryModel,
  ProductFieldsModel,
} from '../../../models/product-library';
import {
  fetchProductLibraryItems,
  paginateProductLibrary,
  searchProductsByName,
  setSearchQuery,
} from '../../../store/features/masterLibrarianList/masterLibrarianListSlice';
import { useAppDispatch, useAppSelector } from '../../../store/index';
import DropdownMenu from '../../containers/DropdownMenu';
import ProductsTable from '../../containers/Tables/ProductsTable';
import NoResultsFound from '../../elements/NoResultsFound';
import ViewWrapper from '../../elements/ViewWrapper';

import { formatDate } from '../../../util';
import './MasterLibrarianListView.less';

const antSpinIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;

const { Search } = Input;
const { Title, Text } = Typography;

interface SearchBarProps {
  searchingProducts: boolean;
  searchQuery: string;
  updateSearchQuery: (query: string) => void;
}

const SearchBar = ({
  searchingProducts,
  searchQuery,
  updateSearchQuery,
}: SearchBarProps) => {
  return (
    <div className="master-librarian-list__search-bar">
      <div className="master-librarian-list__search-bar-left">
        <Search
          className="master-librarian-list__search-input"
          allowClear={!searchingProducts}
          value={searchQuery}
          onChange={(e) => updateSearchQuery(e.target.value)}
          enterButton
          placeholder="Search for products..."
          suffix={searchingProducts && <Spin indicator={antSpinIcon} />}
        />
      </div>
      <div className="master-librarian-list__search-bar-right">
        <DropdownMenu requireSelectedProduct={true} />
      </div>
    </div>
  );
};

const MasterLibrarianListView = () => {
  const dispatch = useAppDispatch();

  const {
    value: productLibrary,
    fetchingProductLibraryItems,
    totalCount,
    pagination,
    searchingProductLibrary,
    allProductLibraryItems,
    searchQuery,
  } = useAppSelector((state) => state.masterLibrarianList);

  const debouncedSearchQuery = useDebounce<string>(searchQuery, 500);

  const hasNoSearchResultsFound = useMemo(
    () =>
      !fetchingProductLibraryItems &&
      !!searchQuery &&
      productLibrary.length === 0,
    [fetchingProductLibraryItems, searchQuery, productLibrary.length]
  );

  const hasProductLibraryItems = !(
    !fetchingProductLibraryItems && allProductLibraryItems.length === 0
  );

  const hasMoreProductLibraryItems =
    hasProductLibraryItems &&
    pagination?.offset! + pagination?.offset! < totalCount!;

  const isPermittedToLoadMore =
    !fetchingProductLibraryItems &&
    !searchingProductLibrary &&
    !searchQuery &&
    hasMoreProductLibraryItems;

  useEffect(() => {
    dispatch(
      searchProductsByName({
        query: debouncedSearchQuery,
      })
    );
  }, [debouncedSearchQuery, dispatch]);

  useEffect(() => {
    dispatch(fetchProductLibraryItems());
  }, [dispatch]);

  const updateSearchQuery = useCallback(
    (query: string) => {
      dispatch(setSearchQuery(query));
    },
    [dispatch]
  );

  const renderReUploaded = (fields: ProductFieldsModel[], fieldKey: string) => {
    const fieldValue = fields.find((field) => field.field_key === fieldKey);
    return <>{fieldValue ? 'Yes' : 'No'}</>;
  };

  const columns: ColumnsType<ListProductLibraryModel> = useMemo(
    () => [
      {
        title: 'Model',
        dataIndex: 'data',
        key: 'model',
        width: 100,
        render: (_, productItem) => {
          return (
            <div className="center-cell">
              <Link
                to={{
                  pathname: `/product-library/${productItem.uuid}`,
                  state: {
                    productItem,
                  },
                }}
              >
                <Image
                  style={{
                    width: '100%',
                    maxHeight: '130px',
                    objectFit: 'cover',
                  }}
                  src={
                    productItem.image_url
                      ? productItem.image_url
                      : imagePlaceholder
                  }
                  alt={`${productItem.name}`}
                  preview={false}
                />
              </Link>
            </div>
          );
        },
      },
      {
        title: 'UPC',
        dataIndex: 'upc',
        key: 'upc',
        width: 150,
        render: (upc: string) =>
          !!upc ? (
            <Tooltip title={upc} mouseEnterDelay={1}>
              <Text ellipsis style={{ maxWidth: '400px' }}>
                {upc}
              </Text>
            </Tooltip>
          ) : (
            <Text type="secondary" style={{ fontStyle: 'italic' }}>
              N/A
            </Text>
          ),
        sorter: (a: ListProductLibraryModel, b: ListProductLibraryModel) =>
          Number(a?.upc?.replace(/\D/g, '')) -
          Number(b?.upc?.replace(/\D/g, '')),
      },
      {
        title: 'Brand',
        dataIndex: 'brand_name',
        key: 'brand',
        width: 100,
        render: (brand: string) =>
          !!brand ? (
            <Tooltip title={brand} mouseEnterDelay={1}>
              <Text style={{ maxWidth: '150px' }}>{brand}</Text>
            </Tooltip>
          ) : (
            <Text type="secondary" style={{ fontStyle: 'italic' }}>
              No brand.
            </Text>
          ),

        sorter: (a: ListProductLibraryModel, b: ListProductLibraryModel) =>
          a.brand?.name.localeCompare(b.brand?.name),
      },
      {
        title: 'Product Name',
        dataIndex: 'name',
        key: 'name',
        width: 150,
        render: (productName: string) =>
          !!productName ? (
            <Tooltip title={productName} mouseEnterDelay={1}>
              <Text style={{ maxWidth: '150px' }}>{productName}</Text>
            </Tooltip>
          ) : (
            <Text type="secondary" style={{ fontStyle: 'italic' }}>
              No product name.
            </Text>
          ),
        sorter: (a: ListProductLibraryModel, b: ListProductLibraryModel) =>
          a?.name?.localeCompare(b?.name),
      },
      {
        title: 'Size',
        dataIndex: 'size',
        key: 'size',
        width: 100,
        render: (size: number) =>
          !!size ? (
            <Tooltip title={size} mouseEnterDelay={1}>
              <Text style={{ maxWidth: '150px' }}>{size}</Text>
            </Tooltip>
          ) : (
            <Text type="secondary" style={{ fontStyle: 'italic' }}>
              No size.
            </Text>
          ),
        sorter: (a: ListProductLibraryModel, b: ListProductLibraryModel) => {
          // For product items that are null/undefined
          const aSize = `${a.size}`?.split(' ')?.[0];
          const bSize = `${b.size}`?.split(' ')?.[0];
          if (aSize && bSize) {
            return Number(aSize) - Number(bSize);
          } else if (aSize) {
            return 1;
          } else if (bSize) {
            return -1;
          } else {
            return 0;
          }
        },
      },
      {
        title: 'Count',
        dataIndex: 'count',
        key: 'count',
        width: 100,
        render: (count: string) =>
          !!count ? (
            <Tooltip title={count} mouseEnterDelay={1}>
              <Text style={{ maxWidth: '150px' }}>{count}</Text>
            </Tooltip>
          ) : (
            <Text type="secondary" style={{ fontStyle: 'italic' }}>
              No count.
            </Text>
          ),
        sorter: (a: ListProductLibraryModel, b: ListProductLibraryModel) => {
          // For product items that are null/undefined
          const aCount = a.count?.split(' ')?.[0];
          const bCount = b.count?.split(' ')?.[0];
          if (aCount && bCount) {
            return Number(aCount) - Number(bCount);
          } else if (aCount) {
            return 1;
          } else if (bCount) {
            return -1;
          } else {
            return 0;
          }
        },
      },
      {
        title: 'Model Date',
        dataIndex: 'created_at',
        key: 'created_at',
        width: 150,
        defaultSortOrder: 'ascend',
        render: (date: string) => {
          return <div>{date ? formatDate(date) : null}</div>;
        },
        sorter: (a: ListProductLibraryModel, b: ListProductLibraryModel) =>
          new Date(b?.created_at).getTime() - new Date(a?.created_at).getTime(),
      },
      {
        title: 'Re Uploaded',
        dataIndex: 'fields',
        key: 'notes',
        width: 150,
        render: (fields: ProductFieldsModel[]) => (
          <Text type="secondary" style={{ fontStyle: 'italic' }}>
            {renderReUploaded(fields, 'reuploaded')}
          </Text>
        ),
      },
      {
        title: 'Master librarian notes',
        dataIndex: 'notes',
        key: 'notes',
        width: 150,
      },
    ],
    []
  );

  const paginate = useCallback(
    (page: number, pageSize: number) =>
      dispatch(
        paginateProductLibrary({ page, pageSize, status: 'needs_approval' })
      ),
    [dispatch]
  );

  return (
    <ViewWrapper>
      <div className="master-librarian-list">
        <div className="master-librarian-list__header">
          <Title level={4}>{totalCount} Products Need Your Attention</Title>
        </div>

        <div className="master-librarian-list__search-bar-container">
          <SearchBar
            searchQuery={searchQuery}
            searchingProducts={searchingProductLibrary}
            updateSearchQuery={updateSearchQuery}
          />
        </div>
        <div className="master-librarian-list__content">
          {hasProductLibraryItems && hasNoSearchResultsFound ? (
            <NoResultsFound
              searchQuery={searchQuery}
              onClear={() => updateSearchQuery('')}
            />
          ) : (
            <ProductsTable
              totalCount={totalCount}
              paginate={paginate}
              dataSource={productLibrary}
              loading={fetchingProductLibraryItems || searchingProductLibrary}
              hasMore={isPermittedToLoadMore}
              columns={columns}
              headerTitle="Master Librarian List"
              hasFooterBulkEditButton
              hasFooterDeleteButton
              noDataDescription="All products have been processed!"
              tableHeight="calc(100vh - 250px)"
            />
          )}
        </div>

        <BackTop />
      </div>
    </ViewWrapper>
  );
};

export default MasterLibrarianListView;
