import { FC, memo, useCallback, useMemo } from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Space,
  Switch,
  Typography,
} from 'antd';
import { History, Location } from 'history';
import { withRouter } from 'react-router-dom';

import { ApiError, handleError } from '../../../../api/base';
import { FormatModel, initialNewFormatModel } from '../../../../models/format';
import { ProductStatusEnum } from '../../../../models/product-library';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  createCustomFieldOption,
  deleteCustomFieldOption,
  fetchFormats,
  updateCustomFieldOption,
} from '../../../../store/features/metaData/metaDataSlice';
import {
  getMePermissions,
  propsAreEqual,
  UserPermissions,
} from '../../../../util';
import FormWrapper from '../../../elements/FormWrapper';
import { RoutePath } from '../../../views/AppRoot/types';
import './FormatForm.less';
import { FormatFormLocationState } from './types';

interface FormatFormProps {
  history: History;
  location: Location<FormatFormLocationState>;
}

interface FormatProps {
  name: string;
  status: string;
}

const FormatForm: FC<FormatFormProps> = (props) => {
  const { history, location } = props;
  const dispatch = useAppDispatch();
  const { deletingCustomFieldOption, savingCustomFieldOption } = useAppSelector(
    (state) => state.metaData
  );
  const [form] = Form.useForm();

  const formatLocationData = location.state?.data;
  const isNewFormat = useMemo(() => !formatLocationData, [formatLocationData]);
  const fields = useMemo(
    () => Object.keys(isNewFormat ? initialNewFormatModel : formatLocationData),
    [isNewFormat, formatLocationData]
  );

  const currentUserPermissions = useMemo(
    (): string[] => getMePermissions(),
    []
  );

  const onError = useCallback((err: ApiError) => {
    handleError(err);
  }, []);

  const saveFormat = useCallback(
    async (format: FormatModel) => {
      const { name, statusCheckValue } = format;

      let status: string;
      let newFormat: FormatProps = { name: '', status: '' };

      if (statusCheckValue) {
        status = ProductStatusEnum.approved;
      } else {
        status = ProductStatusEnum.needs_approval;
      }

      newFormat = {
        name,
        status,
      };

      const res: any = isNewFormat
        ? await dispatch(
            createCustomFieldOption({
              customFieldKey: 'format',
              customFieldOption: newFormat,
            })
          )
        : await dispatch(
            updateCustomFieldOption({
              customFieldKey:
                formatLocationData.custom_field_id as unknown as string,
              optionId: formatLocationData.id,
              customFieldOption: newFormat,
            })
          );

      if (res.error) {
        onError({ error: res.payload.error });
      } else {
        message.success('Format saved.');
        await dispatch(fetchFormats());
        history.goBack();
      }
    },
    [
      dispatch,
      history,
      isNewFormat,
      formatLocationData?.custom_field_id,
      formatLocationData?.id,
      onError,
    ]
  );

  const onSubmit = useCallback(
    (values: FormatModel) => {
      form.validateFields(fields).then(() => saveFormat(values));
    },
    [fields, form, saveFormat]
  );

  const getInitialValues = () => {
    if (isNewFormat) {
      return { ...initialNewFormatModel };
    }
    const { status } = formatLocationData;
    return {
      ...formatLocationData,
      statusCheckValue: status === ProductStatusEnum.approved,
    };
  };

  const handleDeleteFormat = useCallback(async () => {
    message.loading(
      {
        content: 'Deleting format...',
        key: 'deleting-format-message',
      },
      0
    );

    const res: any = await dispatch(
      deleteCustomFieldOption({
        customFieldKey: 'format',
        optionId: formatLocationData.id,
      })
    );
    if (res.error) {
      onError({ error: res.payload.error });
    } else {
      message.destroy('deleting-format-message');
      message.success(`Format '${formatLocationData.name}' deleted.`);
      await dispatch(fetchFormats());
      history.goBack();
    }
  }, [
    dispatch,
    formatLocationData?.id,
    formatLocationData?.name,
    onError,
    history,
  ]);

  const handleConfirmDeleteFormat = useCallback(
    (e: any) => {
      e.preventDefault();
      Modal.confirm({
        title: `Are you sure you want to delete the format "${formatLocationData.name}"?`,
        icon: <ExclamationCircleOutlined />,
        content: `You will no longer be able to add products to the ${formatLocationData.name} format after it has been deleted`,
        okType: 'danger',
        okText: 'Delete',
        onOk: handleDeleteFormat,
      });
    },
    [formatLocationData?.name, handleDeleteFormat]
  );

  return (
    <FormWrapper
      title={`${isNewFormat ? 'Create new' : 'Edit'} format`}
      onClose={() => history.push(RoutePath.MetaData)}
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={getInitialValues()}
        onFinish={onSubmit}
      >
        <Form.Item
          label="Format"
          name="name"
          rules={[{ required: true, message: 'Format name is required.' }]}
        >
          <Input data-cy="format-form-input" placeholder="Format name" />
        </Form.Item>
        <Form.Item label="Status">
          <div className="format-form-status">
            <Typography>Librarian Approved</Typography>
            <Form.Item name="statusCheckValue" valuePropName="checked">
              <Switch
                disabled={
                  !currentUserPermissions.includes(
                    UserPermissions.MetaDataApprove
                  )
                }
              />
            </Form.Item>
          </div>
        </Form.Item>
        {!isNewFormat ? (
          <Form.Item label="Details">
            <div className="format-form-details">
              <Typography>Number of models in library</Typography>
              <Typography>{formatLocationData.in_library}</Typography>
            </div>
            <div className="format-form-details">
              <Typography>
                Number of models in processing / master librarian list
              </Typography>
              <Typography>{formatLocationData.in_processing}</Typography>
            </div>
            <Button
              className="link-button"
              type="link"
              onClick={() =>
                history.push({
                  state: { format: formatLocationData },
                  pathname: RoutePath.ProductLibrary,
                })
              }
            >
              View in Library
            </Button>
          </Form.Item>
        ) : null}
        <Form.Item>
          {isNewFormat ? (
            <Space style={{ float: 'right' }}>
              <Button onClick={() => history.goBack()}>Cancel</Button>
              <Button
                loading={savingCustomFieldOption}
                data-cy="format-form-submit-btn"
                htmlType="submit"
                type="primary"
                disabled={
                  !currentUserPermissions.includes(
                    UserPermissions.CustomFieldsCreate
                  )
                }
              >
                Add
              </Button>
            </Space>
          ) : (
            <Space style={{ float: 'right' }}>
              {!formatLocationData.in_use ? (
                <Button
                  loading={deletingCustomFieldOption}
                  danger
                  type="primary"
                  onClick={handleConfirmDeleteFormat}
                  disabled={
                    !currentUserPermissions.includes(
                      UserPermissions.CustomFieldsDelete
                    )
                  }
                >
                  Delete
                </Button>
              ) : null}
              <Button
                loading={savingCustomFieldOption}
                type="primary"
                htmlType="submit"
                disabled={
                  !currentUserPermissions.includes(
                    UserPermissions.CustomFieldsEdit
                  )
                }
              >
                Save
              </Button>
            </Space>
          )}
        </Form.Item>
      </Form>
    </FormWrapper>
  );
};

export default withRouter<any, any>(memo(FormatForm, propsAreEqual));
