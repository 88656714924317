import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiError, handleError, PaginationModel } from '../../../api/base';
import ProductLibraryService, {
  UpdateProductRequest,
} from '../../../api/product-library';
import {
  FiltersType,
  SelectedFilters,
} from '../../../components/views/ProductLibraryView/components/ProductsFilters/ProductsFilters';
import { ListProductLibraryParams } from '../../../hooks/product-library';
import { ListProductLibraryModel } from '../../../models/product-library';
import { convertParamsToQuery } from '../../../util';
import { RootState } from '../../index';
import { SelectDataItem } from '../../../components/elements/MultiLevelSelect/types';

interface ProductLibraryError {
  code: number | null;
  message: string | null;
}

export interface ProductLibraryState {
  value: ListProductLibraryModel[];
  productUPCs: { [fieldName: string]: ListProductLibraryModel | undefined };
  allProductLibraryItems: ListProductLibraryModel[];
  fetchingProductLibraryItems: boolean;
  searchingProductLibrary: boolean;
  savingProductLibrary: boolean;
  error: ProductLibraryError;
  pagination: PaginationModel | null;
  totalCount: number;
  searchQuery: string;
  selectedCategory: SelectDataItem | 'All' | undefined;
  selectedFilters: SelectedFilters | undefined;
  currentPage: number | undefined;
  pageSize: number | undefined;
}

interface UpdateProductProps {
  productId: string;
  product: UpdateProductRequest;
}

interface PaginateProductLibraryProps {
  page: number | undefined;
  pageSize: number | undefined;
  status?: string;
}

const initialState: ProductLibraryState = {
  value: [],
  productUPCs: {},
  allProductLibraryItems: [],
  fetchingProductLibraryItems: false,
  searchingProductLibrary: false,
  savingProductLibrary: false,
  error: { code: null, message: null },
  totalCount: 0,
  pagination: null,
  searchQuery: '',
  selectedCategory: undefined,
  selectedFilters: undefined,
  currentPage: 1,
  pageSize: 100,
};

export const fetchProductLibrary = createAsyncThunk(
  'productLibrary/fetchProductLibrary',
  async (
    {
      params,
      searching = false,
      loadMore = false,
    }: {
      params: ListProductLibraryParams;
      searching?: boolean;
      loadMore?: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const queryString = convertParamsToQuery(params);
      const resp = await ProductLibraryService().listProductLibrary(
        queryString
      );
      return {
        data: resp.data,
        pagination: resp.pagination,
        searching,
        loadMore,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const searchProductsByName = createAsyncThunk(
  'productLibrary/searchProductsByName',
  async (query: string, { getState, rejectWithValue }) => {
    try {
      const {
        productLibrary: { pagination },
      } = getState() as RootState;

      let params: ListProductLibraryParams = {
        _limit: pagination?.limit,
        _offset: 0,
        _order_by: 'updated_at:desc',
      };

      if (query && query.length) {
        params = {
          ...params,
          q: `${query.toLowerCase()}`,
        };
      }

      const queryString = convertParamsToQuery(params);
      const resp = await ProductLibraryService().searchProductLibrary(
        queryString
      );

      return {
        data: resp.data,
        pagination: resp.pagination,
        searching: false,
        loadMore: false,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const searchProductsByUPC = createAsyncThunk(
  'productLibrary/searchProductsByUPC',
  async (
    { query, fieldKey }: { query: string; fieldKey: number },
    { getState, rejectWithValue }
  ) => {
    try {
      const {
        productLibrary: { pagination },
      } = getState() as RootState;

      let params: ListProductLibraryParams = {
        _limit: pagination?.limit,
        _offset: 0,
        _order_by: 'updated_at:desc',
      };

      if (query && query.length) {
        params = {
          ...params,
          q: `${query.toLowerCase()}`,
        };
      }

      const queryString = convertParamsToQuery(params);
      const resp = await ProductLibraryService().searchProductLibrary(
        queryString
      );

      return {
        data: resp.data,
        pagination: resp.pagination,
        fieldKey,
        searching: false,
        loadMore: false,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const filterProducts = createAsyncThunk(
  'productLibrary/filterProducts',
  async (
    {
      params,
      query,
    }: {
      params: ListProductLibraryParams;
      query?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      let updatedParams = params;

      if (query && query?.length) {
        updatedParams = {
          ...updatedParams,
          q: query.toLowerCase(),
          status: 'approved',
        };
      }
      const queryString = convertParamsToQuery(updatedParams);

      const resp = await ProductLibraryService().searchProductLibrary(
        queryString
      );

      return {
        data: resp.data,
        pagination: resp.pagination,
        searching: false,
        loadMore: false,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const paginateProductLibrary = createAsyncThunk(
  'productLibrary/paginateProductLibrary',
  async (
    { page, pageSize, status }: PaginateProductLibraryProps,
    { getState, rejectWithValue }
  ) => {
    try {
      if (!page || !pageSize) return;

      const limit = pageSize;
      const nextOffset = (page - 1) * pageSize;
      const {
        productLibrary: { selectedCategory, selectedFilters, searchQuery },
      } = getState() as RootState;

      let nextParams: ListProductLibraryParams = {
        _limit: limit,
        _offset: nextOffset,
        _order_by: 'updated_at:desc',
        status: status || 'approved',
      };

      if (selectedFilters) {
        Object.keys(selectedFilters).forEach((filterGroup) => {
          const filterKey = filterGroup as keyof FiltersType;

          if (selectedFilters[filterKey]?.length) {
            nextParams = {
              ...nextParams,
              [filterGroup]: selectedFilters[filterKey],
            };
          }
        });
      }

      if (selectedCategory && selectedCategory !== 'All') {
        nextParams = {
          ...nextParams,
          category: selectedCategory.key,
        };
      }

      if (searchQuery) {
        nextParams = {
          ...nextParams,
          q: searchQuery,
        };
      }

      const query = convertParamsToQuery(nextParams);
      const resp = await ProductLibraryService().searchProductLibrary(query);

      return {
        data: resp.data,
        pagination: resp.pagination,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const loadMoreProductLibraryItems = createAsyncThunk(
  'productLibrary/loadMoreProductLibraryItems',
  async (
    { hasMoreProductLibraryItems }: { hasMoreProductLibraryItems?: boolean },
    { getState, rejectWithValue }
  ) => {
    try {
      if (!hasMoreProductLibraryItems) return;

      const {
        productLibrary: {
          pagination,
          selectedCategory,
          selectedFilters,
          searchQuery,
        },
      } = getState() as RootState;

      const limit = pagination?.limit!;
      const offset = pagination?.offset!;
      const nextOffset = limit + offset;

      let nextParams: ListProductLibraryParams = {
        _limit: limit,
        _offset: nextOffset,
        _order_by: 'updated_at:desc',
        status: 'approved',
      };

      if (selectedFilters) {
        Object.keys(selectedFilters).forEach((filterGroup) => {
          const filterKey = filterGroup as keyof FiltersType;

          if (selectedFilters[filterKey]?.length) {
            nextParams = {
              ...nextParams,
              [filterGroup]: selectedFilters[filterKey],
            };
          }
        });
      }

      if (selectedCategory && selectedCategory !== 'All') {
        nextParams = {
          ...nextParams,
          category: selectedCategory.key,
        };
      }

      if (searchQuery) {
        nextParams = {
          ...nextParams,
          q: searchQuery,
        };
      }

      const query = convertParamsToQuery(nextParams);
      const resp = await ProductLibraryService().searchProductLibrary(query);

      return {
        data: resp.data,
        pagination: resp.pagination,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateProduct = createAsyncThunk(
  'productLibrary/updateProduct',
  async ({ productId, product }: UpdateProductProps, { rejectWithValue }) => {
    try {
      const resp = await ProductLibraryService().updateProduct(
        productId,
        product
      );
      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const productLibrarySlice = createSlice({
  name: 'productLibrary',
  initialState,
  reducers: {
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    removeProductUPC(
      state,
      {
        payload: { fieldKey, action },
      }: PayloadAction<{
        fieldKey: number | string;
        action: 'UNSET' | 'DELETE';
      }>
    ) {
      if (action === 'DELETE') {
        delete state.productUPCs[fieldKey];
      } else {
        state.productUPCs[fieldKey] = undefined;
      }
    },
    resetProductUPCs(state) {
      state.productUPCs = {};
    },
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
      state.pagination = {
        offset: 0,
        limit: state.pagination?.limit ?? 10,
        count: state.pagination?.count ?? 0,
      };
    },
    setSelectedCategory: (
      state,
      action: PayloadAction<SelectDataItem | 'All' | undefined>
    ) => {
      state.selectedCategory = action.payload;
      state.pagination = {
        offset: 0,
        limit: state.pagination?.limit ?? 10,
        count: state.pagination?.count ?? 0,
      };
    },
    setSelectedFilters: (state, action: PayloadAction<SelectedFilters>) => {
      state.selectedFilters = action.payload;
      state.pagination = {
        offset: 0,
        limit: state.pagination?.limit ?? 10,
        count: state.pagination?.count ?? 0,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductLibrary.pending, (state) => {
        state.fetchingProductLibraryItems = true;
      })
      .addCase(fetchProductLibrary.fulfilled, (state, action) => {
        state.totalCount = action.payload.pagination.count;
        state.fetchingProductLibraryItems = false;
        state.value = action.payload.data;
        state.allProductLibraryItems = action.payload.data;
        state.pagination = action.payload.pagination;
      })
      .addCase(fetchProductLibrary.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingProductLibraryItems = false;
      });

    builder
      .addCase(searchProductsByName.pending, (state) => {
        state.fetchingProductLibraryItems = true;
        state.searchingProductLibrary = true;
      })
      .addCase(searchProductsByName.fulfilled, (state, action) => {
        state.fetchingProductLibraryItems = false;
        state.searchingProductLibrary = false;
        state.value = action.payload.data;
        state.pagination = action.payload.pagination;
      })
      .addCase(searchProductsByName.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingProductLibraryItems = false;
        state.searchingProductLibrary = false;
      });

    builder
      .addCase(searchProductsByUPC.pending, (state) => {
        state.fetchingProductLibraryItems = true;
        state.searchingProductLibrary = true;
      })
      .addCase(searchProductsByUPC.fulfilled, (state, action) => {
        state.fetchingProductLibraryItems = false;
        state.searchingProductLibrary = false;
        state.productUPCs[action.payload.fieldKey] = action.payload.data[0];
        state.pagination = action.payload.pagination;
      })
      .addCase(searchProductsByUPC.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingProductLibraryItems = false;
        state.searchingProductLibrary = false;
      });

    builder
      .addCase(filterProducts.pending, (state) => {
        state.fetchingProductLibraryItems = true;
      })
      .addCase(filterProducts.fulfilled, (state, action) => {
        state.totalCount = action.payload.pagination.count;
        state.fetchingProductLibraryItems = false;
        state.pagination = action.payload.pagination;
        state.allProductLibraryItems = action.payload.data;
        state.value = action.payload.data;
      })
      .addCase(filterProducts.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingProductLibraryItems = false;
      });

    builder
      .addCase(loadMoreProductLibraryItems.pending, (state) => {
        state.fetchingProductLibraryItems = true;
      })
      .addCase(loadMoreProductLibraryItems.fulfilled, (state, action) => {
        state.fetchingProductLibraryItems = false;
        if (action.payload) {
          state.pagination = action.payload.pagination;
          state.value = [...state.value, ...action.payload.data];
          state.fetchingProductLibraryItems = false;
        }
      })
      .addCase(loadMoreProductLibraryItems.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingProductLibraryItems = false;
      });

    builder
      .addCase(paginateProductLibrary.fulfilled, (state, action) => {
        state.fetchingProductLibraryItems = false;
        if (action.payload) {
          state.pagination = action.payload.pagination;
          state.value = [...action.payload.data];
        }
      })
      .addCase(paginateProductLibrary.pending, (state) => {
        state.fetchingProductLibraryItems = true;
      })
      .addCase(paginateProductLibrary.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingProductLibraryItems = false;
      });

    builder
      .addCase(updateProduct.fulfilled, (state) => {
        state.savingProductLibrary = false;
      })
      .addCase(updateProduct.pending, (state) => {
        state.savingProductLibrary = true;
      })
      .addCase(updateProduct.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.savingProductLibrary = false;
      });
  },
});

export const getProductLibrary = (state: RootState) =>
  state.productLibrary.value;
export const getProductLibraryUPCs = (state: RootState) =>
  state.productLibrary.productUPCs;
export const getFetchingProductLibrary = (state: RootState) =>
  state.productLibrary.fetchingProductLibraryItems;
export const getFetchingProductLibraryError = (state: RootState) =>
  state.productLibrary.error;
export const getTotalCount = (state: RootState) =>
  state.productLibrary.totalCount;
export const getPagination = (state: RootState) =>
  state.productLibrary.pagination;
export const getSearchingProductLibrary = (state: RootState) =>
  state.productLibrary.searchingProductLibrary;
export const getSavingProductLibrary = (state: RootState) =>
  state.productLibrary.savingProductLibrary;
export const getAllProducts = (state: RootState) =>
  state.productLibrary.allProductLibraryItems;

export const {
  setPageSize,
  setCurrentPage,
  removeProductUPC,
  resetProductUPCs,
  setSearchQuery,
  setSelectedCategory,
  setSelectedFilters,
} = productLibrarySlice.actions;
export default productLibrarySlice.reducer;
