import { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, Space, Table, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useHistory } from 'react-router-dom';

import useDebounce from '../../../../../hooks/useDebounce';
import { BrandModel } from '../../../../../models/brand';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import {
  paginateBrands,
  searchBrandsByName,
} from '../../../../../store/features/brand/brandSlice';
import { UserPermissions, getMePermissions } from '../../../../../util';
import { DrawerHashRoute } from '../../../../containers/Drawers/types';
import NoResultsFound from '../../../../elements/NoResultsFound';
import SearchBar from '../../../../elements/SearchBar/SearchBar';
import { RoutePath } from '../../../AppRoot/types';

const { Text } = Typography;

const BrandsTab = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const debouncedSearchQuery = useDebounce<string>(searchQuery, 500);

  const {
    value: brands,
    allBrandsValue,
    fetchingBrands,
    searchingBrands,
    totalCount,
    current,
  } = useAppSelector((state) => state.brand);

  const hasNoSearchResultsFound = useMemo(
    () => !fetchingBrands && !!searchQuery && allBrandsValue.length === 0,
    [fetchingBrands, searchQuery, allBrandsValue.length]
  );

  const hasBrands = !fetchingBrands && brands.length > 0;

  const currentUserPermissions = useMemo(
    (): string[] => getMePermissions(),
    []
  );

  const columns: ColumnsType<BrandModel> = [
    {
      title: 'Brand',
      dataIndex: 'name',
      width: '35%',
      sorter: (a, b) => (a.name > b.name ? 1 : -1),
    },
    {
      title: '# in Processing',
      dataIndex: 'in_processing',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.in_processing - b.in_processing,
    },
    {
      title: '# in Library',
      dataIndex: 'in_library',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.in_library - b.in_library,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      defaultSortOrder: 'descend',
      sorter: (a, b) => {
        if (a.status && b.status) {
          return a.status > b.status ? 1 : -1;
        } else {
          return 0;
        }
      },
      render: (status: string) => {
        if (status === 'approved') {
          return <Text>{status}</Text>;
        } else {
          return <Text type="danger">Needs Approval</Text>;
        }
      },
    },
    {
      title: 'Actions',
      width: 100,
      render: (brand: BrandModel) => {
        return (
          <Space direction="horizontal" size="middle">
            {currentUserPermissions.includes(UserPermissions.MetaDataEdit) ? (
              <Button
                type="link"
                className="link-button"
                onClick={() =>
                  history.push({
                    hash: DrawerHashRoute.BrandForm,
                    state: { data: brand },
                  })
                }
              >
                Edit
              </Button>
            ) : null}
            <Button
              className="link-button"
              type="link"
              onClick={() => {
                history.push({
                  state: { brand },
                  pathname: RoutePath.ProductLibrary,
                });
              }}
            >
              View in Library
            </Button>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(searchBrandsByName(debouncedSearchQuery));
  }, [debouncedSearchQuery, dispatch]);

  const updateSearchQuery = useCallback((query: string) => {
    setSearchQuery(query);
  }, []);

  const paginate = useCallback(
    (page: number, pageSize: number) =>
      dispatch(
        paginateBrands({
          page,
          pageSize,
          queryStr: debouncedSearchQuery,
        })
      ),
    [debouncedSearchQuery, dispatch]
  );

  const paginationSetting = {
    hideOnSinglePage: false,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    onChange: paginate,
    onShowSizeChange: paginate,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    style: {
      marginTop: '0px',
      padding: '2rem 1rem',
      background: 'white',
      borderRadius: '0 0 4px 4px',
    },
    total: totalCount,
    current,
  };

  return (
    <div>
      <SearchBar
        searchQuery={searchQuery}
        updateSearchQuery={updateSearchQuery}
        isSearching={searchingBrands}
        canCreate={currentUserPermissions.includes(
          UserPermissions.MetaDataCreate
        )}
        createLink={DrawerHashRoute.BrandForm}
        createButtonText="Add New Brand"
        placeholder="Search Brands"
      />
      {hasBrands && hasNoSearchResultsFound ? (
        <NoResultsFound
          searchQuery={searchQuery}
          onClear={() => updateSearchQuery('')}
        />
      ) : (
        <Table
          loading={searchingBrands}
          sortDirections={['ascend', 'descend', 'ascend']}
          columns={columns}
          dataSource={brands}
          pagination={paginationSetting}
        />
      )}
    </div>
  );
};

export default BrandsTab;
