import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ApiError, handleError, PaginationModel } from '../../../api/base';
import FiltersService from '../../../api/filters';
import { convertParamsToQuery } from '../../../util';
import { SelectDataItem } from '../../../components/elements/MultiLevelSelect/types';

interface ModelLibrarySearchError {
  code: number | null;
  message: string | null;
}

interface FiltersData {
  data: {
    categories?: SelectDataItem[];
    brands?: FiltersData[];
    tags?: FiltersData[];
    format?: FiltersData[];
    market?: FiltersData[];
    quality?: FiltersData[];
    private?: FiltersData[];
    flex?: FiltersData[];
    drc?: FiltersData[];
    parentco?: FiltersData[];
  };
}

export interface ModelLibrarySearchState {
  availableFilters: FiltersData[];
  fetchingModelLibraryFilters: boolean;
  error: ModelLibrarySearchError;
  pagination: PaginationModel | null;
}

const initialState: ModelLibrarySearchState = {
  availableFilters: [],
  fetchingModelLibraryFilters: false,
  error: { code: null, message: null },
  pagination: null,
};

export const fetchAvailableFilters = createAsyncThunk(
  'modelLibrarySearch/fetchAvailableFilters',
  async (
    {
      params,
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const queryString = convertParamsToQuery(params);
      const resp = await FiltersService().getFilters(queryString);

      return {
        data: resp.data,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const modelLibrarySearchSlice = createSlice({
  name: 'modelLibrarySearch',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAvailableFilters.pending, (state) => {
        state.fetchingModelLibraryFilters = true;
      })
      .addCase(fetchAvailableFilters.fulfilled, (state, action) => {
        state.fetchingModelLibraryFilters = false;
        state.availableFilters = action.payload.data as FiltersData[];
      })
      .addCase(fetchAvailableFilters.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingModelLibraryFilters = false;
      });
  },
});

export default modelLibrarySearchSlice.reducer;
